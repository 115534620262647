import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule, NgClass, NgIf, NgStyle} from '@angular/common';
import {BottomPanelComponentModule} from '@components/ui/bottom-panel/item.module';
import {ButtonComponentModule} from '@components/ui/button/item.module';
import {QrCodeModalComponent} from '@components/modals/qr-code/item';
import {IconComponentModule} from '@components/ui/icon/item.module';
import {ConfirmModalComponent} from './confirm-modal/item';
import {ImageUploadModalComponent} from 'app/components/modals/image-upload-modal/item';
import {PlaceModalComponent} from '@components/modals/place/item';
import {AllPlacesModalComponent} from '@components/modals/all-places/item';
import {StockModalComponent} from '@components/modals/stock/item';
import {LoaderComponentModule} from '@components/ui/loader/item.module';
import {ProductGroupModalComponent} from '@components/modals/productGroup/item';
import {ProductModalComponent} from '@components/modals/product/item';
import {QRCodeModule} from 'angular2-qrcode';
import {DirectivesModule} from '@core/directives/directives.module';
import {ModalHeaderModule} from 'app/components/ui/modal-header/item.module';
import {ErrorModalComponent} from '@components/modals/error-modal/item';
import {InputComponentModule} from '@components/form/input/item.module';
import {TextareaComponentModule} from '@components/form/textarea/item.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MainModalComponent} from '@components/modals/main-modal/item';
import {BottomMainModalBtnComponentModule} from '@components/ui/bottom-main-modal-btn/item.module';
import {ReferralModalComponent} from '@components/modals/referral/item';
import {PushModalComponent} from "@components/modals/push-modal/item";


@NgModule({
  imports: [
    IonicModule,
    NgStyle,
    NgClass,
    NgIf,
    BottomPanelComponentModule,
    BottomMainModalBtnComponentModule,
    ButtonComponentModule,
    CommonModule,
    FormsModule,
    IconComponentModule,
    LoaderComponentModule,
    QRCodeModule,
    DirectivesModule,
    ModalHeaderModule,
    InputComponentModule,
    TextareaComponentModule,
    ReactiveFormsModule
  ],
  exports: [
    QrCodeModalComponent,
    ConfirmModalComponent,
    ErrorModalComponent,
    ImageUploadModalComponent,
    ProductModalComponent,
    ProductGroupModalComponent,
    PlaceModalComponent,
    AllPlacesModalComponent,
    StockModalComponent,
    MainModalComponent,
    ReferralModalComponent,
    PushModalComponent
  ],
  declarations: [
    QrCodeModalComponent,
    ConfirmModalComponent,
    ErrorModalComponent,
    ImageUploadModalComponent,
    ProductModalComponent,
    ProductGroupModalComponent,
    PlaceModalComponent,
    AllPlacesModalComponent,
    StockModalComponent,
    MainModalComponent,
    ReferralModalComponent,
    PushModalComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalsModule {
}
