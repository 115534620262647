import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NavigationService} from '@services/navigation.service';
import {ProductItem} from '@entities/menu.entity';


@Component({
  selector: 'app-search',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() items = [];
  @Output() searchResults = new EventEmitter<any[]>();
  @Output() searchTermLength = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter<void>();
  @Output() focus = new EventEmitter<void>();
  // параметр по которому будет осуществляться поиск
  @Input() valueBySearch = '';
  @Input() menuSearch = false;
  searchTerm = '';
  showResults = false;
  searchItems = [];

  ngOnInit() {}

  changeInput(event?: any) {
    if (this.searchTerm?.length) {
      this.searchTermLength.emit(true);
      const items = this.items;
      this.searchItems =  items.filter(item =>
        item[this.valueBySearch].toLowerCase().includes(this.searchTerm.toLowerCase())
      );
      if (!this.searchItems.length) {
        this.showResults = false;
        this.searchResults.emit([]);
      } else {
        this.showResults = true;
        this.searchResults.emit(this.searchItems);
      }
    } else {
      this.searchResults.emit([]);
      this.searchTermLength.emit(false);
    }
  }

  blur() {
    console.log('blur');
  }
}
