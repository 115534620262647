import {Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalComponent} from '@core/modal.component';
import {ProductItem} from '@entities/menu.entity';
import Swiper from 'swiper';
import {ModalService} from '@services/modal.service';
import {CatalogService} from '@models/catalog.service';
import {ProductModalComponent} from '@components/modals/product/item';

@Component({
  selector: 'app-product-group-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class ProductGroupModalComponent extends ModalComponent implements OnInit, OnDestroy  {
  @Input() products: ProductItem[];
  productId: number = null;
  imageLoaded = false;
  isLoaded: boolean[] = [];
  swiper1!: Swiper;
  swiper2!: Swiper;
  @ViewChild('slider1') slider1: ElementRef;
  @ViewChild('slider2') slider2: ElementRef;
  private modalService = inject(ModalService);
  private catalogService = inject(CatalogService);

  ngOnInit() {
    // console.log({products: this.products});
  }

  ngOnDestroy(): void {}

  onImageLoad() {
    // Изображение успешно загрузилось, скрываем лоадер
    this.imageLoaded = true;
  }

  onImagesLoad(index: number) {
    this.isLoaded[index] = true;
  }

  init(element: any) {
    this.swiper1 = element.detail[0];
    const currentId = this.products.findIndex(item => item.id === this.productId);
    this.swiper1.slideTo(currentId, 0);
  }

  bottomSlider(element: any) {
    this.swiper2 = element.detail[0];
    // делаем так, чтобы при перестывании нижнего свайпера листался ещё и основной
    this.swiper2.on('slideChange', () => {
      const activeIndex = this.swiper2.activeIndex;
      this.swiper1.slideTo(activeIndex, 0);
    });
  }

  openComboItemModal(id: number) {
    const product = this.catalogService.productItems.find(obj => obj.id === id);
    if (product) {
      this.modalService.openModal(ProductModalComponent,
        {
          product,
          customBreakpoints: [0, 0.90]}
        );
    } else {
      console.log('Данного продукта нет в каталоге');
    }
  }
}
