import {inject, Injectable} from '@angular/core';
import {Camera, CameraOptions} from '@awesome-cordova-plugins/camera/ngx';
import {Platform} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {AppService} from '@services/app.service';
import {UserModelService} from '@models/user-model.service';

@Injectable({
  providedIn: 'root',
})
export class CordovaService {
  camera = inject(Camera);
  platform = inject(Platform);
  public appService = inject(AppService);
  public userModelService = inject(UserModelService);
  public keyBoardShowSubject = new BehaviorSubject(false);

  public get keyBoardShow() {
    return this.keyBoardShowSubject.getValue();
  }

  public set keyBoardShow(value) {
    this.keyBoardShowSubject.next(value);
  }

  isCordova() {
    return this.platform.is('cordova');
  }

  public makePhoto(type, callback: (imageData: string) => void) {
    if (this.isCordova()) {
      this.appService.showLoading();
      const options: CameraOptions = {
        quality: 100,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        correctOrientation: false,
        cameraDirection: this.camera.Direction.FRONT,
        sourceType: type
      };

      // TODO: Сделать проверку на скачки экрана
      this.camera.getPicture(options).then((imageData) => {
        this.appService.hideLoading();
        callback(imageData);

      }, (err) => {
        this.appService.hideLoading();
        // Handle error
        console.log(err);
      });
    }
  }

  getScreenHeight() {
    return this.platform.height();
  }

  getScreenWidth() {
    return this.platform.width();
  }

  getModalSizeMinItem() {
    const screenHeight = this.getScreenHeight();
    return (((12 / screenHeight) * 100) / 10).toFixed(2);
  }

  getModalSizeQrItem() {
    const screenHeight = this.getScreenHeight();
    const barcodeBlock = document.querySelector('.barcode-block');
    const safeAreaTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-top'), 10);
    const barcodeBlockTop = barcodeBlock?.getBoundingClientRect().top + window.pageYOffset - (safeAreaTop ? safeAreaTop : 0) - 7;
    const barcodeBlockPosition = (barcodeBlockTop / screenHeight) * 100;
    const result = this.userModelService.isLogin() ? (1 - (Number(barcodeBlockPosition.toFixed(2)) / 100)).toFixed(2) : 0.75;
    document.documentElement.style.setProperty('--qrsize', result.toString());
    return result;
  }
}
