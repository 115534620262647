import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalComponent} from '@core/modal.component';
import {ContactItem} from '@entities/contact.entity';
import {PlaceModalService} from '@services/placeModal.service';
import {Subscription} from 'rxjs';
import {AppService} from '@services/app.service';


@Component({
  selector: 'app-place-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class PlaceModalComponent extends ModalComponent implements OnInit, OnDestroy  {
  appService = inject(AppService);
  placeModalService = inject(PlaceModalService);
  place: ContactItem;
  imageLoaded = false;
  isLoaded: boolean[] = [];
  private placeSubscription: Subscription;

  ngOnInit() {
    // console.log('place', this.place);
    this.placeSubscription = this.placeModalService.place.subscribe((place) => {
      this.place = place;
    });
  }

  ngOnDestroy(): void {
    this.placeSubscription.unsubscribe();
  }

  ionViewWillEnter() {
    this.appService.bottomMainModal = false;
  }

  ionViewWillLeave() {
    if (this.appService.mainModalIsOpen) {
      this.appService.bottomMainModal = true;
    }
  }

  goToPhotos(place: ContactItem) {
    this.navigationService.goTo('contacts/photos', {}, false, {place});
  }

  onImageLoad() {
    // Изображение успешно загрузилось, скрываем лоадер
    this.imageLoaded = true;
  }

  onImagesLoad(index: number) {
    this.isLoaded[index] = true;
  }
}
