import {Entity} from 'app/core/main.entity';


export class Products extends Entity {
  id: number;
  name: string;
  items?: ProductItem[];
}

export class ProductShort extends Entity {
  id: number;
  name: string;
  defaultImage = '/assets/img/product-default.svg';
  imageUrl: string;
  imageUrlCard: string[];
  isStopList: boolean;
  price: number;
  fprice: number;
  oldPrice: number;
}

export class ProductItem extends ProductShort {
  type: string;
  selectSize: string;
  offerSelectType: string | null;
  offerSelectText: string | null;
  slug: string;
  isCombo: boolean;
  offers: any[];
  comboProducts: ProductShort[];
  companiesOnly: any[];
  branchGroupsOnly: any[];
  categoryId: number;
  subCategoryIndex: null;
  catName: string;
  previewText: string;
  detailText: string;
  weight: string;
  nutritionalValue: NutritionalValue;
}

export class NutritionalValue extends Entity {
  proteins: string;
  fats: string;
  carbo: string;
  kkal: string;
}

export class CategoryItem extends Entity {
  id: number;
  name: string;
  slug: string;
  type: number;
  defaultImg: string;
  picture: string;
  subCategories: CategoryItem[];
}

// export class Settings extends Entity {
//   aboutUs: string;
//   birthdayText: string;
//   dashboard: DashboardItem;
//   phone: string;
//   privacyPolicy: string;
//   socials: any;
//   userPhone: string;
//   version: VersionItem;
//   ymapsApiKey: string;
// }
//
// export class DashboardItem extends Entity {
//   icon: string;
//   icon_background_color: string;
//   question_answer: {answer: string; question: string}[] = [];
//   background: string;
//   title: string;
//   sub_title: string;
//   text: string;
// }
//
// export class VersionItem extends Entity {
//   appStoreUrl: string;
//   googlePlayUrl: string;
//   ios: string;
//   android: string;
//   critical: string;
//   btn: string;
//   text: string;
// }
