import {
    Injectable,
    Injector, NgZone
} from '@angular/core';
import {AppService} from './app.service';
import {filter, first} from 'rxjs/operators';
import {NavigationService} from './navigation.service';
import {UserModelService} from '@models/user-model.service';

// for all APP, CATALOG
// https://z2s.ru/catalog/{catalog_slug}
// https://z2s.ru/catalog/{catalog_slug}/{product_slug}/
// https://z2s.ru/favorite/
// https://z2s.ru/restaurants/
// https://z2s.ru/restaurants/{branch_slug}/ + BRANCHES
// https://z2s.ru/delivery/
// https://z2s.ru/about/
// https://z2s.ru/actions
// https://z2s.ru/actions/{promo_slug}/
// https://z2s.ru/personal/cart/

enum RouteType {
    Menu,
    Contacts,
    Dashboard,
    Surveys,
}

interface Url {
    host: string; // host: "z2s.ru"
    path: string; // path: "/catalog/pizza/"
    scheme: string; // scheme: "https"
    url: string; // url: "https://z2s.ru/catalog/pizza/"
}

@Injectable({
    providedIn: 'root'
})
export class DeepLinkService {
    private readonly injector: Injector;
    private ngZone: any;
    private appService: AppService;
    private navigationService: NavigationService;
    private userService: UserModelService;

    private url: Url = {host: null, path: null, scheme: null, url: null};
    private routeType: RouteType = null;

    constructor(
        ngZone: NgZone,
        injector: Injector,
    ) {
        this.ngZone = ngZone;
        this.injector = injector;
        this.appService = this.injector.get(AppService);
        this.navigationService = this.injector.get(NavigationService);
        this.userService = this.injector.get(UserModelService);

      this.appService.platform.ready().then(() => {

        this.appService.getWindow().IonicDeeplink?.route({}, (match) => {
            this.openDeepLink(match.$link);
          }, (noMatch) => {
            this.openDeepLink(noMatch.$link);
          }
        );
        document.addEventListener('click', (event: any) => {
          const target = event.target.closest('a');
          if (target) {
            event.preventDefault();
            event.stopPropagation();
            this.openInnerLink(target.href);
          }
        });
      });
    }

  openInnerLink(href) {
    let eventUrl: URL = null;
    try {
      eventUrl = new URL(href);
    } catch (e) {
      return;
    }
    const protocol = eventUrl.protocol?.length && eventUrl.protocol[eventUrl.protocol?.length - 1] === ':' ?
      eventUrl.protocol.slice(0, -1) :
      eventUrl.protocol;
    const url: Url = {
      host: eventUrl.host,
      path: eventUrl.pathname,
      scheme: protocol,
      url: eventUrl.href,
    };
    // хостнеймы google и apple нужны, чтобы корректно открывалась ссылка на экране обновления приложения.
    if (['www.z2s.ru', 'z2s.ru', 'play.google.com', 'apps.apple.com'].includes(url.host)) {
      this.openDeepLink(url);
    } else {
      // this.openInAppBrowser();
    }
  }

  openDeepLink(url: Url) {
    this.setUrl(url);
    this.routeType = this.getRoute();
    if (this.routeType === null) {
        this.appService.openLink(url.url);
    }
    this.getRouteReady()
        .then(() => {
            this.startRoute();
        })
        .catch((err) => {
            console.log('DeepLinkService.getRouteReady reject: not all Promises resolved', err);
        });
  }

  private setUrl(url) {
      this.url = url;
      let path = this.url.path;
      path = path?.length && path[0] === '/' ? path.substring(1) : path;
      path = path?.length && path[path.length - 1] === '/' ? path.slice(0, -1) : path;
      this.url.path = path;
  }

  private getRoute(): RouteType {

      const regexpList: { [key in RouteType]?: RegExp } = {
          [RouteType.Menu]: /^menu$/,
          [RouteType.Contacts]: /^contacts$/,
          [RouteType.Dashboard]: /^dashboard$/,
          [RouteType.Surveys]: /^surveys$/,
      };
      for (const key in regexpList) {
          if (regexpList.hasOwnProperty(key) && regexpList[key].test(this.url.path)) {
              return Number(key);
          }
      }
      return null;
  }

  private getRouteReady(): Promise<any> {
      const appReady = this.appService.appReadySubject.pipe(filter(v => v), first()).toPromise();

      return Promise.all([
          appReady
      ]);
  }

  private startRoute() {
      this.ngZone.run(() => {
          switch (this.routeType) {
              case RouteType.Contacts: {
                  this.navigationService.goTo('contacts');
                  break;
              }
              case RouteType.Menu: {
                  this.navigationService.goTo('menu');
                  break;
              }
              case RouteType.Surveys: {
                  this.navigationService.goTo('surveys');
                  break;
              }
              default:
                  this.navigationService.goTo('dashboard', true);
                  break;
          }
      });
  }
}
