import {Component, EventEmitter, Input, NgZone, Output, ViewChild} from '@angular/core';
import {Animation, IonButton} from '@ionic/angular';

@Component({template: ''})
// @ts-ignore
export abstract class IonButtonBaseComponent implements IonButton {

  @ViewChild('ionButton', {static: false}) ionButtonElement;

  @Input() buttonType: string;
  @Input() ionColor: any;
  @Input() disabled: boolean;
  @Input() download: string | undefined;

  @Input() expand: 'full' | 'block';
  @Input() fill: 'clear' | 'outline' | 'solid' | 'default';
  @Input() form: string | HTMLFormElement;
  @Input() href: string | undefined;
  @Input() ionMode: 'ios' | 'md' = 'md';
  @Input() rel: string | undefined;

  @Input() shape: 'round';
  @Input() ionSize: 'small' | 'default' | 'large';
  @Input() strong: boolean;
  @Input() target: string | undefined;
  @Input() type: 'submit' | 'reset' | 'button';

  @Output() ionBlur: EventEmitter<CustomEvent<void>> = new EventEmitter();
  @Output() ionFocus: EventEmitter<CustomEvent<void>> = new EventEmitter();

  routerDirection: any;

  protected el: HTMLElement;
  protected z: NgZone;

  routerAnimation(baseEl: any, opts: any): Animation | any {
    return this.ionButtonElement.routerAnimation(baseEl, opts);
  }

  onBlur(event: CustomEvent<FocusEvent> | any) {
    this.ionBlur.emit(event);
  }

  onFocus(event: CustomEvent<FocusEvent> | any) {
    this.ionFocus.emit(event);
  }

}
