import {NgModule} from '@angular/core';
import {BottomPanelComponent} from './item';
import {IonicModule} from '@ionic/angular';
import {NgClass, NgIf, NgStyle} from '@angular/common';

@NgModule({
  imports: [
    IonicModule,
    NgStyle,
    NgClass,
    NgIf
  ],
  exports: [
    BottomPanelComponent
  ],
  declarations: [BottomPanelComponent],
  providers: [],
})
export class BottomPanelComponentModule {
}
