import {Icons} from '@entities/enums';
import {Subject} from 'rxjs';

export type BottomMenuItem = {
  id: string;
  icon: Icons;
  title?: string;
  link?: string;
};

export class BottomMenu {
  hidden = false;
  items: BottomMenuItem[] = [];
  private clickSubject: Subject<number> = new Subject<number>();

  constructor(items: BottomMenuItem[] = []) {
    if (items?.length) {
      this.items = items;
    }
  }

  onClick() {
    return this.clickSubject.asObservable();
  }

  show() {
    this.hidden = false;
  }

  hide() {
    this.hidden = true;
  }

  toggle() {
    this.hidden = !this.hidden;
  }

  setItems(items: BottomMenuItem[]) {
    this.items = items;
  }

  getItemByIndex(index) {
    return this.items[index] ? this.items[index] : null;
  }

  getItems() {
    return this.items?.length ? this.items : [];
  }

  emitClick(index: number) {
    this.clickSubject.next(index);
  }
}
