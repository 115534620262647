import {NgModule} from '@angular/core';
import {SuppressScrollEventDirective} from './supressScroll.directive';

@NgModule({
  imports: [],
  declarations: [
    SuppressScrollEventDirective,
  ],
  exports: [
    SuppressScrollEventDirective,
  ]
})
export class DirectivesModule {
}
