import {inject} from '@angular/core';
import {UserModelService} from '@models/user-model.service';
import {NavigationService} from '@services/navigation.service';

export const ROUTER_IS_LOGIN = '/dashboard';
export const ROUTER_IS_LOGOUT = '/auth';

export const authGuard = () => {
  const userModelService = inject(UserModelService);
  const navigationService = inject(NavigationService);
  if (!userModelService.isLogin()) {
    navigationService.goTo(ROUTER_IS_LOGOUT);
  }
  return userModelService.isLogin();
};
export const logoutGuard = () => {
  const userModelService = inject(UserModelService);
  const navigationService = inject(NavigationService);
  if (userModelService.isLogin()) {
    navigationService.goTo(ROUTER_IS_LOGIN);
  }
  return !userModelService.isLogin();
};
