import {Component, HostBinding, inject, Input} from '@angular/core';
import {CordovaService} from '@services/cordova.service';
import {AppService} from '@services/app.service';

@Component({
  selector: 'app-bottom-panel',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class BottomPanelComponent {
  cordovaService = inject(CordovaService);
  appService = inject(AppService);
  @Input() color: 'dark' | 'light' = 'light';
  @Input() hidden = false;
  @Input() position: 'fixed' | 'static' = 'fixed';

  @HostBinding('class')
  get classes() {
    return {
      [`bottom-panel-color-${this.color}`]: this.color,
      ['bottom-panel-hidden']: this.hidden || this.cordovaService.keyBoardShow || this.appService.bottomPanelNeedHidden,
      [`bottom-panel-position-${this.position}`]: this.position
    };
  }
}
