import {Component, inject, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FirebaseService} from '@services/firebase.service';
import {Subscription} from 'rxjs';
import {CordovaService} from '@services/cordova.service';
import {UserModelService} from '@models/user-model.service';
import {NavigationService} from '@services/navigation.service';

@Component({
  template: ''
})
export abstract class ModalComponent {
  @ViewChild('scrollContainer') scrollContainer;

  public cordovaService = inject(CordovaService);
  public modalController = inject(ModalController);
  public userModelService = inject(UserModelService);
  public navigationService = inject(NavigationService);
  public firebaseService = inject(FirebaseService);
  protected subscriptions: Subscription[] = [];

  constructor() {
    this.onTouchStart = this.onTouchStart.bind(this);
  }


  closeModal(data: any = null) {
    return this.modalController.dismiss(data);
  }

  public addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  ionViewWillEnter() {
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }

      this.subscriptions = [];
    }
  }

  ionViewDidEnter() {
    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.addEventListener('touchstart', this.onTouchStart);
    }
  }

  ionViewDidLeave() {
    removeEventListener('touchstart', this.onTouchStart);
  }

  private onTouchStart(e) {
    if (this.scrollContainer.nativeElement.scrollTop > 0) {
      e.stopImmediatePropagation();
    }
  }
}
