import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, inject, ViewChild} from '@angular/core';
import {ModalComponent} from '@core/modal.component';
import { StockItem } from '@entities/stock.entity';
import Swiper from 'swiper';
import {FormGroup} from '@angular/forms';
import {CatalogService} from '@models/catalog.service';
import {FeedbackModelService} from '@models/feedback.service';
import {InputState} from '@entities/enums';
import {AppService} from '@services/app.service';
import {IonContent} from '@ionic/angular';
import {CordovaService} from '@services/cordova.service';

@Component({
  selector: 'app-stock-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class StockModalComponent extends ModalComponent implements AfterViewInit {
  cdr = inject(ChangeDetectorRef);
  appService = inject(AppService);
  catalogService = inject(CatalogService);
  feedbackModelService = inject(FeedbackModelService);
  cordovaService = inject(CordovaService);
  stocks: StockItem[];
  stockId: number;
  isLoaded: boolean[] = [];
  formSendingId = null;
  formSendingLoader = false;
  resultMessage = '';
  swiper!: Swiper;
  @ViewChild('slider') slider: ElementRef;
  @ViewChild(IonContent) ionContent: IonContent;

  getFormItemById(id: number): FormGroup {
    return this.catalogService.formItemsArray.controls.find(formGroup => formGroup.get('id').value === id) as FormGroup;
  }

  getFormControlState(controlName, id: number) {
    const formItem = this.getFormItemById(id);
    const control = formItem.get(controlName);
    return (control.invalid && !control.untouched ? 'error' : null) as InputState;
  }

  getFormControlError(controlName, id: number) {
    const formItem = this.getFormItemById(id);
    const control = formItem.get(controlName);
    return (control.invalid && !control.untouched ? 'error' : null) as InputState;
  }

  ngAfterViewInit() {
    const style = document.createElement('style');
    style.innerHTML = `
      .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination-bullet {
        transition: width .3s;
        border: none;
        display: block;
      }
      .swiper-pagination-bullet-active {
        width: var(--pagination-bullet-width-active, 8px);
        border-radius: var(--pagination-bullet-radius-active, 50%);
      };
    `;
    this.slider.nativeElement.shadowRoot.appendChild(style);
  }

  ionViewWillEnter() {
    this.appService.bottomMainModal = false;
  }

  ionViewWillLeave() {
    if (this.appService.mainModalIsOpen) {
      this.appService.bottomMainModal = true;
    }
    this.clearAfterSendFormMessage();
  }

  init(element: any) {
    this.swiper = element.detail[0];
    const currentId = this.stocks.findIndex(item => item.id === this.stockId);
    this.swiper.slideTo(currentId, 0);
    this.swiper.on('slideChange', () => {
      this.clearAfterSendFormMessage();
    });
  }

  onImagesLoad(index: number) {
    this.isLoaded[index] = true;
  }

  async sendForm(id: number) {
    this.formSendingId = id;
    this.formSendingLoader = true;
    const res = await this.feedbackModelService.sendForm(this.getFormItemById(id).value);
    if (res) {
      this.resultMessage = res.message;
      this.formSendingLoader = false;
      if (res.status) {
        this.clearForm(id);
      }
    }
  }

  clearAfterSendFormMessage() {
    this.formSendingId = null;
    this.resultMessage = '';
  }

  clearForm(id: number) {
    const formItem = this.getFormItemById(id);

    if (formItem) {
      // очщиаем значени кроме id и помечаем все контролы как untouched
      Object.keys(formItem.controls).forEach(controlName => {
        if (controlName !== 'id') {
          const control = formItem.get(controlName);
          control.setValue('');
          control.markAsUntouched();
        }
      });
    }
  }

  handleFocus(textarea = false) {
    this.appService.bottomPanelNeedHidden = true;
    if (textarea) {
      this.ionContent.scrollToBottom(0);
    }
  }

  blurFocus() {
    this.appService.bottomPanelNeedHidden = false;
  }
}
