import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule, NgClass, NgIf, NgStyle} from '@angular/common';
import {IconComponentModule} from '@components/ui/icon/item.module';
import {SearchComponent} from '@components/ui/search/item.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    IonicModule,
    NgStyle,
    NgClass,
    NgIf,
    IconComponentModule,
    FormsModule,
    CommonModule
  ],
  exports: [
    SearchComponent
  ],
  declarations: [SearchComponent],
  providers: [],
})
export class SearchComponentModule {
}
