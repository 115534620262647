import {IonButtonBaseComponent} from '@core/ion-components/ionButton';
import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {ButtonColors, ButtonSize, Icons} from '@entities/enums';

@Component({
  selector: 'app-button',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class ButtonComponent extends IonButtonBaseComponent {
  @Input() icon: Icons;
  @Input() color: ButtonColors = 'primary';
  @Input() size: ButtonSize = 'medium';
  @Input() buttonFontSize: ButtonSize = 'medium';
  @Input() loading = false;
  @Input() notification = -1;   // (== 0)  - show notification dot; (> 0)  - show notification bubble with notification number

  @Output() clickButton: EventEmitter<void> = new EventEmitter();

  @HostBinding('class')
  get classes() {
    return {
      [`button-shape-${this.shape}`]: this.shape,
      [`button-color-${this.color}`]: this.color,
      [`button-size-${this.size}`]: this.size,
      [`button-font-size-${this.buttonFontSize}`]: this.buttonFontSize,
      [`button-expand-${this.expand}`]: this.expand,
      [`button-loading`]: this.loading,
      'show-notification': this.notification === 0
    };
  }

  ngOnInit() {
    this.ionColor = 'default';
  }

  getIconUrl() {
    return this.icon ? `/assets/icons/${this.icon}.svg` : null;
  }

  onClick() {
    this.clickButton.emit();
  }
}
