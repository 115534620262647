import {inject, Injectable} from '@angular/core';
import {NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {Location} from '@angular/common';
import {NavController, Platform} from '@ionic/angular';
import {AppService} from './app.service';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  router = inject(Router);
  location = inject(Location);
  navController = inject(NavController);
  appService = inject(AppService);
  platform = inject(Platform);
  // routerOutlet = inject(IonRouterOutlet);

  public previousUrl;
  public currentUrl;

  public routeStack = [];
  data: any;
  private lastBackButtonPressTime = 0;

  constructor() {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        const routeData = data.state.root.firstChild.data;
        if (routeData?.showMenu) {
          this.appService.bottomMenu.show();
        } else {
          this.appService.bottomMenu.hide();
        }
      }
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url.replace(/^\/+/g, '');
    });

    if (this.appService.isAndroid()) {
      // чтобы на андроиде двойной клик назад на странице dashboard приводил к выходу из приложения
      document.addEventListener('backbutton', (event) => {
        const now = new Date().getTime();
        const backButtonDelay = 1000; // время задержки между нажатиями кнопки назад
        if (now - this.lastBackButtonPressTime < backButtonDelay && this.currentUrl === 'dashboard') {
          (navigator as any).app.exitApp(); // выход из приложения
        } else {
          this.lastBackButtonPressTime = now;
        }
      }, false);
    }
  }

  public back(fullBack = false) {
    if (this.appService.back()) {
      if (this.canGoBackIfNotStartPage()) {
        this.pushToStack(this.currentUrl);
        this.navController.navigateBack('dashboard');
      } else {
        if (fullBack === false && this.canGoBack()) {
          this.navController.navigateBack([this.routeStack.pop()]);
        } else {
          this.navController.navigateBack([this.routeStack[0]]);
          this.routeStack = [];
        }
      }
    }
  }

  canGoBackIfNotStartPage() {
    return !this.isCurrentUrl('dashboard') && this.routeStack.length === 0;
  }

  canGoBack(index = 1) {
    return this.canGoBackIfNotStartPage() ? true : this.routeStack.length !== 0;
  }

  goTo(url: string, opt = {}, replaceUrl = false, data = {}) {
    this.data = data;
    this.pushToStack(this.currentUrl, replaceUrl);
    if (replaceUrl) {
      return this.navController.navigateRoot([url], {queryParams: opt, state: data, replaceUrl});
    } else {
      return this.navController.navigateForward([url], {queryParams: opt, state: data, replaceUrl});
    }
  }

  isCurrentUrl(url) {
    return this.currentUrl?.includes(url);
  }

  pushToStack(url: string, replaceUrl = false) {
    if (replaceUrl) {
      this.routeStack = [];
    } else {
      if (typeof url !== 'undefined') {
        this.routeStack.push(url);
      }
    }
  }

}
