import {NgModule} from '@angular/core';
import { ModalHeaderComponent } from './item';
import {IonicModule} from '@ionic/angular';
import {NgClass, NgIf, NgStyle} from '@angular/common';
import { ButtonComponentModule } from "app/components/ui/button/item.module";

@NgModule({
  imports: [
    IonicModule,
    NgStyle,
    NgClass,
    NgIf,
    ButtonComponentModule
  ],
  exports: [
    ModalHeaderComponent
  ],
  declarations: [ModalHeaderComponent],
  providers: [],
})
export class ModalHeaderModule {
}
