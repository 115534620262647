import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  userPhone: string = null;
  prevPhone: string = null;
  token: string = null;
  timer = 60;
  currentTimer = 0;
}
