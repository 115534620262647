import {Component} from '@angular/core';
import {ModalComponent} from 'app/core/modal.component';

// DON'T FORGET INCLUDE MODULE OF MODAL INTO PAGE MODULE, or it will not work
@Component({
  selector: 'app-push-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
})
export class PushModalComponent extends ModalComponent {

  title: string;
  text: string;
  cancelText = '';
  confirmText = '';

  closeModal(answer: boolean = false) {
    return super.closeModal({answer});
  }
}
