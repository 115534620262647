import {Component, inject, OnInit} from '@angular/core';
import {MainComponent} from '@core/main.component';
import {ProductItem} from '@entities/menu.entity';
import {ModalService} from '@services/modal.service';
import {UserModelService} from '@models/user-model.service';
import {CatalogService} from '@models/catalog.service';
import {ProductModalComponent} from '@components/modals/product/item';

@Component({
  selector: 'app-child-component',
  template: `
    <div>
      <ng-content></ng-content>
    </div>
  `,
})
export class ChildComponent {}

@Component({
  selector: 'app-search-page',
  templateUrl: 'page.html',
  styleUrls: ['page.scss']
})
export class SearchPageComponent extends MainComponent implements OnInit {
  products: ProductItem[];
  searchTermLength = false;
  isLoaded: boolean[] = [];
  public userModelService = inject(UserModelService);
  public catalogService = inject(CatalogService);
  private modalService = inject(ModalService);

  async ngOnInit() {
  }

  searchResult(result?: ProductItem[]) {
    this.products = result;
  }

  checkTermLength(termLength: boolean) {
    this.searchTermLength = termLength;
  }

  openModal(product: ProductItem) {
    this.modalService.openModal(ProductModalComponent,
      {
        product,
        customBreakpoints: [0, 0.90]
    });
  }

  onImagesLoad(index: number) {
    this.isLoaded[index] = true;
  }
}
