import {NgModule} from '@angular/core';
import {ButtonComponent} from './item';
import {IonicModule} from '@ionic/angular';
import {NgClass, NgIf, NgStyle} from '@angular/common';

@NgModule({
    imports: [
        IonicModule,
        NgStyle,
        NgClass,
        NgIf
    ],
  exports: [
    ButtonComponent
  ],
  declarations: [ButtonComponent],
  providers: [],
})
export class ButtonComponentModule {
}
