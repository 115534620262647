import {NgModule} from '@angular/core';
import {BottomMainModalBtnComponent} from './item';
import {IonicModule} from '@ionic/angular';
import {NgClass, NgForOf, NgIf, NgStyle} from '@angular/common';
import {IconComponentModule} from '@components/ui/icon/item.module';
import {ButtonComponentModule} from '@components/ui/button/item.module';

@NgModule({
  imports: [
    IonicModule,
    NgStyle,
    NgClass,
    NgIf,
    IconComponentModule,
    NgForOf,
    ButtonComponentModule
  ],
  exports: [
    BottomMainModalBtnComponent
  ],
  declarations: [BottomMainModalBtnComponent],
  providers: [],
})
export class BottomMainModalBtnComponentModule {
}
