import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {authGuard, logoutGuard, ROUTER_IS_LOGIN} from '@core/guards/auth.guard';


const routes: Routes = [
  {
    path: 'blank',
    loadChildren: () => import('./pages/blank/page.module').then(m => m.BlankPageModule),
  },
  {
    path: 'auth',
    redirectTo: 'auth/phone',
    pathMatch: 'full'
  },
  {
    path: 'auth/phone',
    loadChildren: () => import('./pages/auth/phone/page.module').then(m => m.AuthPhonePageModule),
    canActivateChild: [logoutGuard]
  },
  {
    path: 'auth/enter-admin',
    loadChildren: () => import('./pages/auth/enter-admin/page.module').then(m => m.EnterAdminPageModule),
    canActivateChild: [authGuard]
  },
  {
    path: 'auth/code',
    loadChildren: () => import('./pages/auth/code/page.module').then(m => m.AuthCodePageModule),
    canActivateChild: [logoutGuard]
  },
  {
    path: 'auth/form',
    loadChildren: () => import('./pages/auth/form/page.module').then(m => m.AuthFormPageModule),
    canActivateChild: [logoutGuard]
  },
  {
    path: 'invite',
    loadChildren: () => import('./pages/invite/page.module').then(m => m.InvitePageModule),
  },
  {
    path: 'call',
    loadChildren: () => import('./pages/call/page.module').then(m => m.CallPagePageModule),
  },
  // {
  //   path: 'pushes',
  //   loadChildren: () => import('./pages/pushes/page.module').then(m => m.PushesPageModule),
  // },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/page.module').then(m => m.DashboardPageModule),
    data: {
      showMenu: true
    }
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/page.module').then(m => m.MenuPageModule),
    data: {
      showMenu: true
    }
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/page.module').then(m => m.SearchPagePageModule),
    data: {
      showMenu: false
    }
  },
  {
    path: 'about-app',
    loadChildren: () => import('./pages/about-app/page.module').then(m => m.AboutAppPagePageModule),
    data: {
      showMenu: false
    }
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/page.module').then(m => m.PrivacyPolicyPagePageModule),
    data: {
      showMenu: false
    }
  },
  {
    path: 'dashboard/stocks',
    loadChildren: () => import('./pages/stocks/page.module').then(m => m.StockPageModule),
  },
  {
    path: 'surveys',
    loadChildren: () => import('./pages/surveys/page.module').then(m => m.SurveysPageModule),
    canActivateChild: [authGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/order/page.module').then(m => m.OrderPageModule),
    canActivateChild: [authGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/page.module').then(m => m.ProfilePageModule),
    canActivateChild: [authGuard]
  },
  {
    path: 'uikit',
    loadChildren: () => import('./pages/uikit/page.module').then(m => m.UikitPageModule),
  },
  {
    path: 'update',
    loadChildren: () => import('./pages/update/page.module').then(m => m.UpdatePageModule),
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/page.module').then(m => m.ContactsPageModule),
    data: {
      showMenu: true
    }
  },
  {
    path: 'contacts/photos',
    loadChildren: () => import('./pages/contacts/photos/page.module').then(m => m.PhotosPageModule),
    data: {
      showMenu: true
    }
  },
  {
    path: 'contacts/photo',
    loadChildren: () => import('./pages/contacts/photo/page.module').then(m => m.PhotoPageModule),
    data: {
      showMenu: true
    }
  },
  {
    path: '',
    redirectTo: ROUTER_IS_LOGIN,
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
