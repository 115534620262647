import { Entity } from '@core/main.entity';

export class StockItem extends Entity {
  id: number;
  slug: string | null;
  title: string;
  text: string;
  detail: string;
  imageUrl: string;
  imageUrlCard: string[];
  fullImageUrl: string;
  roundImageUrl: string;
  promoCode: string;
  defaultImg: string;
  titleColor: string;
  form?: FormItem;
  showForm: boolean;
}

export class FormItem extends Entity {
  id: number;
  title: string;
  name: string;
  phone: string;
  text: string;
}
