import {inject, Injectable} from '@angular/core';
import { MainModelService } from '@core/mainModel.service';
import {StockItem} from '@entities/stock.entity';
import {ApiResponse} from '@entities/apiResponse.entity';
import {plainToInstance} from 'class-transformer';
import {CategoryItem, ProductItem} from '@entities/menu.entity';
import {Icons} from '@entities/enums';
import {FormArray, FormBuilder, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CatalogService extends MainModelService {
  fb = inject(FormBuilder);
  stockItems: StockItem[] = [];
  productItems: ProductItem[] = [];
  categoryItems: CategoryItem[] = [];
  // todo когда на беке будет полностью готов этот блок, его нужно будет описать.
  settings: any;
  socials: {icon: Icons; value: any}[] = [];

  // формы для formItems
  formItems = this.fb.array([]);

  // Добавляем getter для удобства получения FormArray
  get formItemsArray(): FormArray {
    return this.formItems;
  }

  // Метод для инициализации формы на основе массива данных
  formItemsFormArray(dataArray: any[], formArray: FormArray): void {
    for (const data of dataArray) {
      // Создаем FormGroup с данными из data и добавляем его в formArray
      const formGroup = this.fb?.group({
        id: data.id,
        title: data.title,
        name: [data.name, [Validators.required]],
        phone: [data.phone, [Validators.required, Validators.minLength(17)]],
        text: [data.text]
      });

      formArray?.push(formGroup);
    }
  }

  async getCatalog() {
    const catalog = await this.getRequest({
      url: 'api/v1/catalog/app/'
    }) as ApiResponse;

    if (catalog.status) {
      this.stockItems = plainToInstance(StockItem, catalog.result.promotions) as unknown as StockItem[];
      this.formItems?.clear();
      const items = [];
      // если у акции showForm true, то инициализируется форма
      this.stockItems.forEach((item)=> {
        if (item.showForm) {
          item.form = {
            id: item.id,
            title: item.title,
            name: '',
            phone: '',
            text: '',
          };
          items.push(item.form);
        }
      });

      // Инициализируем все имеющиеся формы
      this.formItemsFormArray(items, this.formItemsArray);

      this.productItems = plainToInstance(ProductItem, catalog.result.products) as unknown as ProductItem[];
      this.categoryItems = plainToInstance(CategoryItem, catalog.result.categories) as unknown as CategoryItem[];
      this.settings = catalog.result.settings;
      this.appService.phone = catalog.result.settings?.phone;
      this.socials = Object.keys(this.settings.socials).map(key => ({
        icon: `soc-${key}` as Icons,
        value: this.settings.socials[key],
      }));
    }
  }

  getSocials() {
    return this.socials.filter(obj => obj.value !== '');
  }
}
