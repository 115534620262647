import {Entity} from '@core/main.entity';

export class Profile extends Entity {
  id: string;
  userId: number;
  name: string;
  lastName: string;
  login: string;
  phone: string;
  email: string | null;
  birthday: string;
  gender: number;
  avatarUrl: string;
  guid: string;
  balance: number;
  enableOrderStatusNotification: boolean;
  enablePromotionsNotification: boolean;
  defaultAddress: any;
  company: any;
  referralId: string;
  referrerId: string;
  isAdmin: boolean;
}
