import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ModalComponent} from 'app/core/modal.component';

// DON'T FORGET INCLUDE MODULE OF MODAL INTO PAGE MODULE, or it will not work
@Component({
  selector: 'app-confirm-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
})
export class ConfirmModalComponent extends ModalComponent {

  title: string;
  text: string;
  cancelText = 'Отменить';
  confirmText = 'Удалить';

  closeModal(answer: boolean = false) {
    return super.closeModal({answer});
  }
}
