import {Component, Input, forwardRef, OnInit, Output, EventEmitter} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './item.html',
  styleUrls: ['./item.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent implements ControlValueAccessor {
  @Input() label = '';
  @Input() control: any = new FormControl();
  @Input() textareaId = '';
  @Input() labelText?: string = null;
  @Input() type = 'text';
  @Input() fontWeight = '400';
  @Input() fontSize = '14px';
  @Input() lineHeight = 'normal';
  @Input() placeholder = '';
  @Input() password = false;
  @Input() autofocus = false;
  @Input() required = false;
  @Input() readonly = false;
  @Input() cols: number;
  @Input() rows: any;
  @Input() error = false;
  @Input() styles = '';
  @Input() maskito: any;
  @Output() focusEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() blurEvent: EventEmitter<void> = new EventEmitter<void>();

  errorMessages: Record<string, string> = {
    required: 'Это обязательное поле',
  };

  // ControlValueAccessor methods
  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  writeValue(value: any): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = (value: any) => {
      this.writeValue(value);  // Добавьте эту строку
      fn(value);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  ngOnInit() {
  }

  focusInEvent() {
    this.focusEvent.emit();
  }

  focusBlur() {
    this.blurEvent.emit();
  }
}
