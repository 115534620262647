import {inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {LoadingController, MenuController, Platform} from '@ionic/angular';
import {environment} from '@environments/environment';
import {BottomMenu} from '@core/classes/bottom-menu';
import {FirebaseX} from '@awesome-cordova-plugins/firebase-x/ngx';
import {FirebasePlugin} from 'cordova-plugin-firebasex';

const _window = (): any => window;
declare let cordova: any;

@Injectable({
  providedIn: 'root',
})
export class AppService {
  title = inject(Title);
  platform = inject(Platform);
  firebasex = inject(FirebaseX);
  loading: any;
  isLoading = false;
  loadingCounter = 0;
  menu: MenuController;
  // modalService = inject(ModalService);
  // toastController = inject(ToastController);
  public appReadySubject = new BehaviorSubject(false);
  bottomMenu = new BottomMenu();
  bottomMainModal = false;
  mainModalIsOpen = false;
  bottomPanelNeedHidden = false;
  phone = '';
  email = '';
  deviceId: string;
  public appVersionNumber: string;
  postError = false;
  httpError = false;
  postErrorList = 0;
  showMainPopup = false;

  constructor(
    public loadingController: LoadingController,
    menu: MenuController) {
    this.menu = menu;
    console.log('%c 🦞 Lobsterlab. Z2S App v:' + this.getVersion(), 'color: #5d81b7; font-size: 18px; font-weight: bold');
    (window as any).app = this;
  }

  public get appReady() {
    return this.appReadySubject.getValue();
  }

  public set appReady(value) {
    this.appReadySubject.next(value);
  }

  setTitle(title: string) {
    this.title.setTitle(title);
  }

  callPhone(phone: string | null = null) {
    this.openLink('tel:' + (phone !== null ? phone : this.phone));
  }

  callChat() {
    console.log('to chat');
  }

  sendMail(mail: string | null = null) {
    this.openLink('mailto:' + (mail !== null ? mail : this.email ? this.email : 'mail@z2s.ru'));
  }

  openLink(url: string) {
    if (this.isCordova()) {
      cordova.InAppBrowser.open(url, '_system', 'location=yes');
    } else {
      window.open(url, '_system', 'location=yes');
    }
  }

  isCordova() {
    return this.platform.is('cordova');
  }

  isAndroid() {
    return this.platform.is('android');
  }

  isIos() {
    return this.platform.is('ios');
  }

  isDevice() {
    return this.isCordova() && (this.isAndroid() || this.isIos());
  }

  getVersion(callback?: () => void) {
    this.appVersionNumber = environment.version;
    return environment.version;
  }

  addUserToPush(userId = null) {
    if (userId) {
      this.firebasex.subscribe(userId);
      this.firebasex.setUserId(userId);
    }
  }

  openMenu() {
    this.menu.open();
  }

  closeMenu() {
    this.menu.close();
  }

  async subscribePush(callback: () => void, callbackPush: (data: any) => void = null) {

    if (this.isIos()) {
      await this.firebasex.subscribe('ios');
    }

    if (this.isAndroid()) {
      await this.firebasex.subscribe('android');
    }

    await this.firebasex.subscribe('all');

    const status = await this.firebasex.hasPermission();
    if (!status) {
      this.firebasex.grantPermission().then((e) => {
      });
    }

    await this.firebasex.getToken().then(token => {
      this.deviceId = token;

      callback();
    });

    this.firebasex.onMessageReceived().subscribe(data => {
      if (callbackPush) {
        callbackPush(data);
      }
    });
  }

  public unSubscribePush(userId = null) {
    this.firebasex.clearAllNotifications();

    if (this.isIos()) {
      this.firebasex.unsubscribe('ios');
    }

    if (this.isAndroid()) {
      this.firebasex.unsubscribe('android');
    }

    if (userId) {
      this.firebasex.unsubscribe(userId);
    }
  }

  async showLoading() {
    this.loadingCounter++;

    if (!this.isLoading) {

      this.isLoading = true;

      this.loading = await this.loadingController.create({
        cssClass: 'custom-loading',
      });
      await this.loading.present();
    }
  }

  async hideLoading(all = false) {
    this.loadingCounter--;

    if (all) {
      this.loadingCounter = 0;
    }

    if (this.loadingCounter <= 0) {

      // Добавляем класс для анимации
      await this.loading?.classList.add('hide-loading');
      // Оборачиваем в setTimeout на 600, чтобы перед dismiss
      // успела отработать анимация плавного скрытия лоадера
      await setTimeout(() => {
        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }

        this.loadingCounter = 0;

        if (this.isLoading) {
          this.isLoading = false;
          return this.loadingController.dismiss(null, 'cancel');
        }
      }, 600);
    }

    return null;
  }

  async back() {
    // if (this.isLoading) {
    //   return false;
    // }

    // close popover
    await this.closeToast();

    // close modal
    // await this.modalService.closeTopModal();

  }


  async closeToast() {
    // try {
    // const element = await this.toastController.getTop();
    // if (element) {
    //   await element.dismiss();
    //   return;
    // }
    // } catch (error) {
    // }
  }

  getWindow() {
    return _window();
  }

  logError(data: {
    title: string;
    type: string;
    data?: any;
    error?: any;
  }) {
    data.data.prodMode = environment.production ? 'production' : 'debug';
    const firebasePlugin: FirebasePlugin = this.getWindow().FirebasePlugin;
    if (!firebasePlugin) {
      console.warn('FirebasePlugin no initialized into window');
      return;
    }
    // Устанавливаем кастомные ключи ошибки
    if (data.data) {
      for (const key in data.data) {
        if (data.data.hasOwnProperty(key)) {
          firebasePlugin.setCrashlyticsCustomKey(key, data.data[key]);
        }
      }
    }
    // Устанавливаем тип ошибки в кастомный ключ
    firebasePlugin.setCrashlyticsCustomKey('type', data.type);

    // Получаем стек ошибки, если можем
    // StackTrace.fromError(data.error)
    //   .then((stack) => {
    //     firebasePlugin.logError(data.title, stack, () => {
    //       console.log('%cSend error to firebase 🔥\n' + '%c' + data.error.stack, 'color:#ff6a00', 'color: #838c95');
    //     });
    //   })
    //   .catch((err) => {
    //     firebasePlugin.logError(data.title, null, () => {
    //       console.log('%cSend error to firebase 🔥\n' + '%c' + data.error.message, 'color:#ff6a00', 'color: #838c95');
    //     });
    //   });
  }
}
