import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {catchError, delay, mergeMap, retryWhen} from 'rxjs/operators';
import {AppService} from 'app/services/app.service';
import {ModalService} from '@services/modal.service';
import {UserModelService} from '@models/user-model.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  userModelService = inject(UserModelService);
  modalService = inject(ModalService);
  ignoreHostsList = ['hatimaki.foquz.ru', 'widget.me-talk.ru'];
  trackError = 'track.php';
  retryes = 1;
  delayTime = 3000;

  constructor(
    private appService: AppService
  ) {
  }


  public async wait(condFunc) {
    return new Promise<void>((resolve) => {
      if (condFunc()) {
        if (navigator.onLine) {
          this.appService.isLoading = false;
        }
        resolve();
      } else {
        setTimeout(async () => {
          await this.wait(condFunc);
          resolve();
        }, 500);
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('intercept', request);
    return next.handle(request).pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        delay(this.delayTime),
        mergeMap(async (error) => {
          // console.log('error', error);

          // alert(navigator.onLine);
          // console.log('navigator.onLine', navigator.onLine);
          if (!navigator.onLine) {
            // this.appService.hideLoading(true);

            if (this.retryes-- > 0) {
              this.retryes++;
              if (!this.appService.postError) {
                if (error.url && error.url.toString().includes(this.trackError) && this.appService.postErrorList < 3) {
                  this.appService.postErrorList++;
                } else {
                  this.openNoInternetErrorModal();
                  // this.appService.hideLoading();
                }
              }

              await this.wait(() => this.appService.httpError === true);

              // this.modalController.dismiss({action: 'close'});
              // this.appService.showLoading();

              return of(error).pipe(
                delay(this.delayTime)
              );
            }
          } else {
            this.appService.isLoading = false;
            // console.log('Try retry online');
            // this.retryes = 0;
            // return of(error).pipe(
            //     delay(this.delayTime)
            // );

            // throwError(error);
            // this.appService.hideLoading();
            throw(error);
          }
          this.retryes = 1;
        })
      )),
      catchError((error: HttpErrorResponse) => {

        // alert(navigator.onLine);

        const url = new URL(error?.url);

        if (url && url.toString().includes(this.trackError) && this.appService.postErrorList < 3) {
          this.appService.postErrorList++;
          return throwError(error);
        }
        if (url && this.ignoreHostsList.includes(url.host)) {
          return throwError(error);
        }
        const data = {
          userId: this.userModelService.profile.userId,
          userToken: this.userModelService.profile.id,
          userPhone: this.userModelService.profile.phone,
          request: JSON.stringify(request),
          requestUrl: request.url,
          error: JSON.stringify(error)
        };
        const err = new Error('HttpErrorInterceptor');
        err.stack = 'Error: HttpErrorInterceptor\n    at intercept.catchError (http-error.interceptor.ts:111:1)';
        this.appService.logError({
          type: 'HttpErrorInterceptor.handleError',
          title: error.message,
          data,
          error: err
        });

        // Защита от повторного запуска модалки
        if (!this.appService.postError) {
          if (navigator.onLine) {
            this.appService.isLoading = false;
            // this.openErrorModal();
          } else {
            this.openNoInternetErrorModal();
          }
        }

        return throwError(error);
      })
    ) as Observable<HttpEvent<any>>;
  }

  async openErrorModal() {
    await this.modalService.openErrorModal({
      title: 'Ошибка',
      text: 'Мы уже работаем над её устранением',
      cannotBeClosed: false
    }, (res) => {
    });
  }

  async openNoInternetErrorModal() {
    // console.log('openNoInternetErrorModal');
    this.appService.httpError = false;
    await this.appService.hideLoading();
    await this.modalService.openErrorModal({
      title: 'Отсутствует интернет соединение',
      text: 'Проверьте соединение и попробуйте подключиться заново',
      confirmText: 'Попробовать снова',
      cannotBeClosed: true,
      internetError: true
    }, (res) => {
    });
  }

}
