import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {Icons} from '@entities/enums';

@Component({
  selector: 'app-page-header',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class PageHeaderComponent {
  @Input() class = '';
  @Input() title = '';
  @Input() newPushes = '';
  @Input() type: 'logo' | 'title' | 'none' | 'search' | 'menu';
  @Input() leftBtn: {
    icon?: Icons;
    title?: string;
  } = null;
  @Input() rightBtn: {
    icon?: Icons;
    title?: string;
  } = null;

  @Output() rightBtnClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() leftBtnClick: EventEmitter<MouseEvent> = new EventEmitter();

  ngOnInit() {
    this.type = this.type ? this.type : this.title ? 'title' : 'logo';
  }

  onLeftBtnClick($event: MouseEvent) {
    this.leftBtnClick.emit($event);
  }

  onRightBtnClick($event: MouseEvent) {
    this.rightBtnClick.emit($event);
  }
}
