import {Component} from '@angular/core';
import {ModalComponent} from 'app/core/modal.component';

// DON'T FORGET INCLUDE MODULE OF MODAL INTO PAGE MODULE, or it will not work
@Component({
  selector: 'app-image-upload-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
})
export class ImageUploadModalComponent extends ModalComponent {

  closeModal(answer: boolean = false) {
    return super.closeModal({answer});
  }

  upload(type: 'CAMERA' | 'PHOTOLIBRARY' = 'PHOTOLIBRARY') {
    this.cordovaService.makePhoto(this.cordovaService.camera.PictureSourceType[type], (image: string) => {
      super.closeModal({answer: image});
    });
  }
}
