import {AfterViewInit, Component, inject} from '@angular/core';
import {ModalComponent} from '@core/modal.component';
import {AppService} from '@services/app.service';
import {StockModalComponent} from '@components/modals/stock/item';
import {CatalogService} from '@models/catalog.service';
import {ModalService} from '@services/modal.service';
import {ProductModalComponent} from '@components/modals/product/item';
import {PlaceModalComponent} from '@components/modals/place/item';
import {ContactsModelService} from '@models/contacts-model.service';
import {ButtonEvent, MainModal} from '@entities/main-modal.entity';
import {MainModalService} from '@services/mainModal.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-main-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class MainModalComponent extends ModalComponent implements AfterViewInit {
  appService = inject(AppService);
  mainModalService = inject(MainModalService);
  mainModal: MainModal;
  catalogService = inject(CatalogService);
  modalService = inject(ModalService);
  contactsModelService = inject(ContactsModelService);
  isLoaded = false;
  text: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngAfterViewInit() {
    this.mainModalService.buttonClick$.subscribe(() => {
      // Обработка события клика
      this.buttonEvent(this.mainModal.buttonEvent);
    });
  }

  ionViewWillEnter() {
    this.appService.mainModalIsOpen = true;
    if (this.mainModal.buttonText) {
      this.appService.bottomMainModal = true;
      this.mainModalService.setButtonText(this.mainModal.buttonText);
    }
    // Вызов метода для обновления текста
    this.updateText();
  }

  ionViewWillLeave() {
    this.appService.mainModalIsOpen = false;
    this.appService.bottomMainModal = false;
  }

  // Обновление текста с использованием DomSanitizer
  updateText() {
    if (this.mainModal.text) {
      this.text = this.sanitizer.bypassSecurityTrustHtml(this.mainModal.text.toString()) as SafeHtml;
    }
  }

  onImagesLoad() {
    this.isLoaded = true;
  }

  buttonEvent(value: ButtonEvent) {
    this.modalService.closeAllModals();
    // открытие модального окна акции
    if (value.link === 'stock-modal') {
      this.modalService.openModal(StockModalComponent,
        {
          classList: 'stock-modal',
          stocks: this.catalogService.stockItems,
          stockId: value.id,
          customBreakpoints: [0, 0.90]
        },
        'full',
        'full',
        (res) => {});
    }
    // открытие модального окна продукта
    else if (value.link === 'product') {
      const product = this.catalogService.productItems.find(obj => obj.id === value.id);
      if (product) {
        this.modalService.openModal(ProductModalComponent,
          {
            product,
            customBreakpoints: [0, 0.90]}
        );
      } else {
        console.log('Данного продукта нет в каталоге');
      }
    }
    // открытие модального окна ресторана
    else if (value.link === 'filial') {
      const place = this.contactsModelService.places.find(obj => obj.id === value.id);
      this.modalService.openModal(
        PlaceModalComponent,
        {place, classList: 'modal-place', modal: '', customBreakpoints: [0, 0.5, 0.75, 1]},
        'full',
        'half',
        (res) => {});
    }
    // переход по ссылке в браузер
    else if (value.link.includes('http://') || value.link.includes('https://')) {
      this.appService.openLink(value.link);
    }
  }
}
