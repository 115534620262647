import {AppService} from 'app/services/app.service';
import {Component, inject, OnDestroy} from '@angular/core';
import {NavigationService} from 'app/services/navigation.service';
import {Subscription} from 'rxjs';
import {UserModelService} from '@models/user-model.service';
import {FirebaseService} from '@services/firebase.service';
import {ContactsModelService} from '@models/contacts-model.service';
import {HelperService} from '@services/helper.service';

@Component({
  template: ''
})
export abstract class MainComponent implements OnDestroy {
  public appService = inject(AppService);
  public navigationService = inject(NavigationService);
  public userModelService = inject(UserModelService);
  public firebaseService = inject(FirebaseService);
  public contactsModelService = inject(ContactsModelService);
  public helperService = HelperService;

  protected subscriptions: Subscription[] = [];

  public addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  ionViewWillEnter() {
    // console.log('data', this.navigationService.router);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }

      this.subscriptions = [];
    }
  }

}
