import {NgModule} from '@angular/core';
import {PageHeaderComponent} from './item';
import {IonicModule} from '@ionic/angular';
import {NgClass, NgIf, NgStyle} from '@angular/common';
import {ButtonComponentModule} from '@components/ui/button/item.module';
import {SearchComponentModule} from '@components/ui/search/item.module';

@NgModule({
  imports: [
    IonicModule,
    NgStyle,
    NgClass,
    NgIf,
    ButtonComponentModule,
    SearchComponentModule
  ],
  exports: [
    PageHeaderComponent
  ],
  declarations: [PageHeaderComponent],
  providers: [],
})
export class PageHeaderComponentModule {
}
