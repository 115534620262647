import {Entity} from 'app/core/main.entity';


export class ContactItem extends Entity {
  id: number;
  coordLat: number;
  coordLon: number;
  slug: string;
  name: string;
  terms: string;
  street: string;
  guid: string;
  metro: string;
  phone: string;
  workingHours: string;
  open: string;
  close: string;
  detailText: string;
  description: string;
  accountNumber: string;
  cookingTime: string;
  mobileCards: boolean;
  notPickup: boolean;
  images: string[];
  timeOptions?: TimeOptions;
}

export class TimeOptions extends Entity {
  start: string;
  end: string;
  delay: string;
  isLoaded: false;
}
