import {NgModule} from '@angular/core';
import {TextareaComponent} from './item';
import {IonicModule} from '@ionic/angular';
import {NgClass, NgIf, NgStyle} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SimpleMaskModule} from '@initru/ngx-ion-simple-mask';
import {IconComponentModule} from '@components/ui/icon/item.module';
import {NgxTippyModule} from 'ngx-tippy-wrapper';

@NgModule({
  imports: [
    IonicModule,
    NgStyle,
    NgClass,
    NgIf,
    SimpleMaskModule,
    FormsModule,
    ReactiveFormsModule,
    IconComponentModule,
    NgxTippyModule,
  ],
  exports: [
    TextareaComponent
  ],
  declarations: [TextareaComponent],
  providers: [],
})
export class TextareaComponentModule {
}
