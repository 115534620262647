import {Component, forwardRef, HostBinding, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {IonInputBaseComponent} from 'app/core/ion-components/ionInput';
import {InputState} from '@entities/enums';

@Component({
  selector: 'app-input',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})

export class InputComponent extends IonInputBaseComponent {

  @Input() color: 'light' | 'dark' = 'light';
  @Input() labelText?: string = null;
  @Input() size: 'medium' | 'large' = 'medium';
  @Input() state: InputState;
  @Input() showErrorElement = false;
  @Input() errorText: string;
  @Input() errorTextPosition: 'left' | 'right' | 'center' = 'left';
  @Input() inputIcon: 'search';
  @Input() inputForCode = false;
  @Input() requiredLabel = false;
  @Input() thousandSeparator = false;
  @Input() addClass = '';
  @Input() tooltipText = '';
  @Input() dontShowDisabled = false;

  @HostBinding('class')
  get classes() {
    return {
      [`input-color-${this.color}`]: this.color,
      [`input-state-${this.state}`]: this.state,
      [`input-size-${this.size}`]: this.size,
      [`input-icon-${this.inputIcon}`]: this.inputIcon,
      'input-icon-show': this.inputIcon,
      'input-for-code': this.inputForCode,
      'input-clear': this.clearInput,
      'input-disabled': this.disabled,
    };
  }

}
