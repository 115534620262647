import {Component, inject, Input} from '@angular/core';
import {ModalComponent} from '@core/modal.component';
import {CatalogService} from '@models/catalog.service';

@Component({
  selector: 'app-qr-code-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class QrCodeModalComponent extends ModalComponent {
  catalogService = inject(CatalogService);
  qrLoaded = false;
  expand = true;
  @Input() internetError = false;

  ionViewDidEnter() {
    this.catalogService.getCatalog();
  }

  onImageLoad() {
    if (this.userModelService.isLogin() && this.userModelService?.profile?.login) {
      // Изображение и qr успешно загрузились, скрываем лоадер
      this.qrLoaded = true;
    }
  }

  closeQrModal() {
    if (this.userModelService.isLogin()) {
      this.closeModal();
    } else {
      this.closeModal();
      this.navigationService.goTo('auth');
    }
  }

  expandQr() {
    this.expand = !this.expand;
  }
}
