import {inject, Injectable} from '@angular/core';
import {MainModelService} from '@core/mainModel.service';
import {ProductItem} from '@entities/menu.entity';
import {ApiResponse } from '@entities/apiResponse.entity';
import {AuthenticationService} from '@services/authentication.service';
import {Profile} from '@entities/profile.entity';
import {plainToInstance} from 'class-transformer';
import {NavigationService} from '@services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class UserModelService extends MainModelService {

  public token = '';
  public profile: Profile = null;
  authService = inject(AuthenticationService);
  navigationService = inject(NavigationService);
  public userOrders: ProductItem[] = [];
  public menuItems = [];
  getToken() {
    return localStorage.getItem('authToken') ?? '';
  }

  setToken(token: string) {
    localStorage.setItem('authToken', token);
    localStorage.setItem('login', this.profile?.login);
  }

  logout() {
    this.appService.unSubscribePush(this.profile.phone);
    this.profile = null;
    localStorage.removeItem('authToken');
    localStorage.removeItem('login');
    this.updateMenuItems();
  }

  isLogin(): boolean {
    return !!localStorage.getItem('authToken');
  }

  getLogin() {
    return localStorage.getItem('login');
  }

  checkPhone(phone: string, smsHash = ''): Promise<ApiResponse> {
    return this.postRequest({
      postData: {
        login: phone,
        smsHash
      },
      url: 'api/v1/personal/getPass/'
    }) as Promise<ApiResponse>;
  }

  sendCode(code: string): Promise<ApiResponse> {
    return this.postRequest({
      postData: {
        login: this.authService.userPhone,
        password: code
      },
      url: 'api/v1/personal/checkPass/'
    }) as Promise<ApiResponse>;
  }

  authUserByAdmin(id: string): Promise<ApiResponse> {
    return this.postRequest({
      postData: {
        userId: id
      },
      url: 'api/v1/personal/authUserByAdmin/'
    }) as Promise<ApiResponse>;
  }

  updateProfile(userData: Partial<Profile>): Promise<ApiResponse> {
    return this.postRequest({
      postData: {profile: userData},
      url: 'api/v1/personal/updateProfile/'
    }) as Promise<ApiResponse>;
  }

  async deleteProfile() {
    const res = await this.postRequest({
      postData: {userId: this.profile.userId},
      url: 'api/v1/personal/deleteProfile/'
    }) as ApiResponse;
    if (res.status) {
      await this.logout();
      await this.appService.unSubscribePush(this.profile.phone);
      await this.navigationService.goTo('');
    }
  }

  async getProfile() {
    if (!this.isLogin()) {
      this.updateMenuItems();
      return;
    }
    const res = await this.getRequest({
      url: 'api/v1/personal/getProfile/'
    }) as ApiResponse;
    if (res.status) {
      this.profile = await plainToInstance(Profile, res.result.item);
      await this.appService.addUserToPush(this.profile.phone);
      await localStorage.setItem('login', this.profile?.login);
      this.updateMenuItems();
    } else {
      this.logout();
    }
  }

  updateMenuItems() {
    this.menuItems = [
      {
        name: 'Абонемент на кофе',
        iconColor: 'orange',
        borderColor: '#FFA959',
        iconBgColor: '#FDE9D5',
        size: 28,
        icon: 'coffee',
        link: '',
        show: false
      },
      {
        name: 'История заказов',
        iconColor: 'purple',
        borderColor: '#535DB7',
        iconBgColor: '#EAE8F9',
        size: 27,
        icon: 'clipboard',
        link: 'orders',
        show: this.isLogin()
      },
      {
        name: 'Пригласи друга',
        iconColor: 'yellow',
        borderColor: '#FFC467',
        iconBgColor: '#FFF1DB',
        size: 27,
        icon: 'addfriend-yellow',
        link: 'invite',
        show: this.isLogin()
      },
      {
        name: 'О нас',
        iconColor: 'green',
        borderColor: '#00D763',
        iconBgColor: '#E1F9E5',
        size: 27,
        icon: 'users',
        link: 'about',
        show: true
      },
      {
        name: 'Контакты',
        iconColor: 'black',
        borderColor: '#979797',
        iconBgColor: '#E9E9E9',
        size: 27,
        icon: 'call',
        link: 'call',
        show: true
      },
      {
        name: 'Войти под админом',
        iconColor: 'yellow',
        borderColor: '#FFC467',
        iconBgColor: '#FFF1DB',
        size: 28,
        icon: 'user',
        link: 'auth/enter-admin',
        show: this.isLogin() && this.profile?.isAdmin
      },
    ];
  }
}
