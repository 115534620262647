import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
})
export class ModalHeaderComponent {
  @Output() clickButton: EventEmitter<void> = new EventEmitter();
  @Input() paddingBottom = '15';
  @Input() bgClass = '';

  constructor() {
  }
}
