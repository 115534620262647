import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {UserModelService} from '@models/user-model.service';
import {AppService} from '@services/app.service';
import {environment} from '@environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private userModelService = inject(UserModelService);
  private appService = inject(AppService);

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes(environment.baseUrl)) {

      const token = localStorage.getItem('authToken');

      let type = 'browser';

      if (this.appService.isCordova()) {
        type = this.appService.isIos() ? 'ios' : 'android';
      }

      if (token !== 'null' && token !== 'undefined' && token) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          }
        });
      }

      request = request.clone({
        setHeaders: {
          Device: type + '-' + this.appService.getVersion(),
        }
      });

      request = request.clone({
        setHeaders: {
          DeviceToken: this.appService.deviceId,
        }
      });
    }

    return next.handle(request);
  }

}
