import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ModalComponent} from 'app/core/modal.component';
import {AppService} from '@services/app.service';
import {ModalService} from '@services/modal.service';
import {QrCodeModalComponent} from '@components/modals/qr-code/item';

// DON'T FORGET INCLUDE MODULE OF MODAL INTO PAGE MODULE, or it will not work
@Component({
  selector: 'app-error-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
})
export class ErrorModalComponent extends ModalComponent implements OnInit, OnDestroy {
  public appService = inject(AppService);
  modalService = inject(ModalService);
  internetError = false;
  fakeModal = false;

  title: string;
  text: string;
  cannotBeClosed = false;
  confirmText = 'Ок';

  ngOnInit() {
    this.appService.isLoading = false;
    this.appService.postError = true;
    if (this.fakeModal) {
      setTimeout(()=> {
        this.closeModal();
      }, 500);
    }
  }

  closeModal(answer: boolean = false) {
    return super.closeModal({answer});
  }

  restartRequest() {
    this.appService.isLoading = true;
    this.appService.httpError = true;
    this.closeModal(true);
  }

  ngOnDestroy() {
    this.appService.postError = false;
  }

  openQr() {
    this.modalService.openModal(
      QrCodeModalComponent,
      {
        internetError: this.internetError,
        customBreakpoints: [
          0,
          this.navigationService.currentUrl.includes('dashboard') ? Number(this.cordovaService.getModalSizeQrItem()) : 0.90
        ]
      },
      this.navigationService.currentUrl.includes('dashboard') ? 'qrsize' : 'full',
      this.navigationService.currentUrl.includes('dashboard') ? 'qrsize' : 'full',
      (res) => {
    });
  }
}
