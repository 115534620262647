import {inject, Injectable} from '@angular/core';
import {ModalController, ModalOptions} from '@ionic/angular';
import {ConfirmModalComponent} from 'app/components/modals/confirm-modal/item';
import {ImageUploadModalComponent} from 'app/components/modals/image-upload-modal/item';
import {ErrorModalComponent} from '@components/modals/error-modal/item';
import {CordovaService} from '@services/cordova.service';
import {MainModalComponent} from '@components/modals/main-modal/item';
import {CatalogService} from '@models/catalog.service';
import {UserModelService} from '@models/user-model.service';


@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modalOpening = false;
  modalController = inject(ModalController);
  cordovaService = inject(CordovaService);
  catalogService = inject(CatalogService);
  userModelService = inject(UserModelService);
  openedModals = [];

  getModalSize(size: string) {
    const map = {
      tiny: this.cordovaService.getModalSizeMinItem(),
      small: 0.25,
      half: 0.5,
      big: 0.75,
      qrsize: this.cordovaService.getModalSizeQrItem(),
      full: 0.90,
    };

    return map[size];
  }

  openModal(
    component,
    data = null,
    maxSize: 'tiny' | 'small' | 'half' | 'big' | 'qrsize' | 'full' = 'full',
    initialSize: 'tiny' | 'small' | 'half' | 'big' | 'qrsize' | 'full' = 'full',
    callback: (data: any) => void = null) {


    const sizes = ['tiny', 'small', 'half', 'big', 'full'];
    const index = sizes.indexOf(maxSize);
    const breakpoints = [0];
    if (index >= 0) {
      sizes.slice(0, index + 1).map((size) => breakpoints.push(this.getModalSize(size)));
    }

    const initialBreakpoint = this.getModalSize(initialSize);
    return this.makeOpenModal({
      component,
      componentProps: data,
      cssClass: `modal-max-size-${maxSize} modal-initial-size-${initialSize} ${data?.modal ?? ''} ${data?.classList ?? ''}`,
      initialBreakpoint,
      breakpoints: data?.customBreakpoints ? data?.customBreakpoints : breakpoints,
      showBackdrop: data?.modal !== 'modal-places',
      backdropDismiss: data?.modal !== 'modal-places',
      backdropBreakpoint: data?.modal === 'modal-places' ? 1 : 0,
      mode: 'md'
    }, callback);

  }

  async makeOpenModal(options: ModalOptions, callback: (data) => void = null) {
    if (this.modalOpening) {
      return null;
    }
    this.modalOpening = true;
    const modal = await this.modalController.create(options);
    await modal.present();
    this.modalOpening = false;
    if (!window.history.state.modal) {
      const modalState = {modal: true};
      history.pushState(modalState, null);
    }

    // добавляем модалку в массив открытых модалок
    // чтобы закрывать все модалки
    this.openedModals.push(modal);

    await modal.onDidDismiss().then((res) => {
      if (callback) {
        callback(res.data);
      }
    });

    return modal;
  }

  async openConfirmModal(data, callback: (answer: boolean) => void) {
    return await this.makeOpenModal({
      component: ConfirmModalComponent,
      showBackdrop: true,
      componentProps: data,
      cssClass: `modal-select`,
      mode: 'md'
    }, (answer) => {
      callback(answer?.answer);
    });
  }

  async openErrorModal(data, callback: (answer: boolean) => void) {
    return await this.makeOpenModal({
      component: ErrorModalComponent,
      showBackdrop: true,
      componentProps: data,
      cssClass: `modal-error ${data.cannotBeClosed ? 'cannotBeClosed' : ''} ${data.fakeModal ? 'fakeModal' : ''}`,
      mode: 'md',
      backdropDismiss: false
    }, (answer) => {
      callback(answer?.answer);
    });
  }


  async openImageUploadModal(data, callback: (answer: string) => void) {
    return await this.makeOpenModal({
      component: ImageUploadModalComponent,
      showBackdrop: true,
      componentProps: data,
      cssClass: `modal-select`,
      mode: 'md'
    }, (answer) => {
      callback(answer?.answer);
    });
  }

  async closeTopModal() {
    try {
      const element = await this.modalController.getTop();
      if (element) {
        await element.dismiss();
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async closeAllModals() {
    for (const modal of this.openedModals) {
      await modal.dismiss();
    }
  }

  async closeModalByClass(classname: string) {
    // closeModalByClass пока не используется, но возможно когда-нибудь сможет пригодиться
    const modal = this.openedModals.find(mod => mod.classList.contains(classname));
    if (modal) {
      modal.dismiss();
      // Удаление закрытой модалки из массива openedModals
      this.openedModals = this.openedModals.filter(m => m !== modal);
    }
  }

  async openMainPopup() {
    const mainPopup = this.catalogService.settings.popups.main;
    // В mainPopup есть данные, открываем модалку
    if (mainPopup && typeof mainPopup === 'object' && Object.keys(mainPopup).length > 0 && this.userModelService.isLogin()) {
      const mainModal = {
        mainTitle: mainPopup.mainTitle,
        imageUrl: mainPopup.imageUrl,
        title: mainPopup.title,
        text: mainPopup.text,
        buttonText: mainPopup.buttonText,
        buttonEvent: {
          id: mainPopup.buttonEvent?.id,
          link: mainPopup.buttonEvent?.link
        },
      };

      // с секундной задержкой открывается модалка
      setTimeout(() => {
        this.openModal(MainModalComponent,
          {
            classList: 'personalized-modal',
            mainModal,
            customBreakpoints: [0, 0.5, 0.90]
          },
          'full',
          'qrsize',
          (res) => {});
      }, 1000);
    }
  }
}
