import {inject, Injectable} from '@angular/core';
import {MainModelService} from '@core/mainModel.service';
import {UserModelService} from '@models/user-model.service';
import {OrdersDataFromFoquz, OrderStatistic} from '@entities/surveys.entity';


@Injectable({
    providedIn: 'root'
})
export class SurveyService extends MainModelService {
  userModelService = inject(UserModelService);

  baseUrl = 'https://z2s.foquz.ru';
  apiUrl = '';
  surveyToken = 'E02SuvlBk6z-Fn7';
  link = `${this.baseUrl}/foquz/api/clients`;
  ordersWithSurveyCount = null;
  ordersWithSurvey: OrdersDataFromFoquz[] = null;
  ordersStatistic: OrderStatistic = null;

  async getSurveysCount() {
    try {
      const getData = {
        'access-token': this.surveyToken,
        phone: this.userModelService?.profile?.phone
      };
      const res = await this.getRequest({
        getData,
        url: `${this.link}/not-done-answers-count/`
      }) as any;
      this.ordersWithSurveyCount = res.count;
    } catch (error) {
      this.ordersWithSurveyCount = null;
      if (error.status === 400 && error?.error?.errors?.phone) {
        // console.log('Опросы: ' + error.error.errors.phone);
      }
    }
  }

  async getOrdersWithSurvey() {
    const getData = {
      'access-token': this.surveyToken,
      phone: this.userModelService?.profile?.phone
    };
    const res = await this.getRequest({
      getData,
      url: `${this.link}/answer-with-orders/`
    }) as any;
    this.ordersWithSurvey = res.items;
  }

  async getOrderStatistic() {
    const getData = {
      'access-token': this.surveyToken,
      phone: this.userModelService?.profile?.phone
    };
    this.ordersStatistic = await this.getRequest({
      getData,
      url: `${this.link}/dish-ratings/`
    }) as any;
  }

  getFormatAvgScore(value: string) {
    if (Number.isInteger(value)) {
      return Number(value).toFixed(2);
    } else {
      return parseFloat(value).toFixed(2);
    }
  }
}
