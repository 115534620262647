import {formatDate} from '@angular/common';
import {environment} from '../../environments/environment';
import {HelperService} from '../services/helper.service';

export class Entity {

  constructor(data: Partial<any> = null) {
    Object.assign(this, data);
  }

  getFormattedDate ?(date: string, format = 'dd.MM.yyyy') {
    try {
      return date ? formatDate(date, format, 'en-EN') : '';
    } catch (e) {
      console.error(e);

      return '';
    }
  }

  getUrl ?(url: string) {
    if (url) {

      if (HelperService.validURL(url) || HelperService.validBlob(url)) {
        return url;
      } else {
        return environment.baseUrl + environment.publicUrl + url.replace(/^\/+/, '');
      }

    } else {
      return null;
    }
  }

  getFormattedPrice ?(price: any) {
    return HelperService.getFormattedPrice(price);
  }

}
