import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalComponent} from '@core/modal.component';
import {CatalogService} from '@models/catalog.service';
import {ModalService} from '@services/modal.service';
import {AppService} from '@services/app.service';

@Component({
  selector: 'app-product-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class ProductModalComponent extends ModalComponent implements OnInit, OnDestroy  {
  @Input() product: any;
  imageLoaded = false;
  isLoaded: boolean[] = [];
  appService = inject(AppService);
  private modalService = inject(ModalService);
  private catalogService = inject(CatalogService);

  ngOnInit() {
    // console.log({product: this.product});
  }

  ngOnDestroy(): void {}

  ionViewWillEnter() {
    this.appService.bottomMainModal = false;
  }

  ionViewWillLeave() {
    if (this.appService.mainModalIsOpen) {
      this.appService.bottomMainModal = true;
    }
  }

  onImageLoad() {
    // Изображение успешно загрузилось, скрываем лоадер
    this.imageLoaded = true;
  }

  onImagesLoad(index: number) {
    this.isLoaded[index] = true;
  }

  openComboItemModal(id: number) {
    const product = this.catalogService.productItems.find(obj => obj.id === id);
    if (product) {
      this.modalService.openModal(ProductModalComponent,
        {
          product,
          customBreakpoints: [0, 0.90]}
      );
    } else {
      console.log('Данного продукта нет в каталоге');
    }
  }
}
