import {NgModule} from '@angular/core';
import {BottomMenuComponent} from './item';
import {IonicModule} from '@ionic/angular';
import {NgClass, NgForOf, NgIf, NgStyle} from '@angular/common';
import {IconComponentModule} from '@components/ui/icon/item.module';

@NgModule({
  imports: [
    IonicModule,
    NgStyle,
    NgClass,
    NgIf,
    IconComponentModule,
    NgForOf
  ],
  exports: [
    BottomMenuComponent
  ],
  declarations: [BottomMenuComponent],
  providers: [],
})
export class BottomMenuComponentModule {
}
