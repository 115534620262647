import {Injectable} from '@angular/core';
import {MainModelService} from '@core/mainModel.service';
import {ContactItem} from '@entities/contact.entity';
import { ApiResponse } from '@entities/apiResponse.entity';
import { plainToInstance } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class ContactsModelService extends MainModelService {

  places: ContactItem[];

  async getContacts() {
    const places =  await this.getRequest({
      url: 'api/v1/restaurants/'
    }) as ApiResponse;

    if (places.status) {
      this.places = plainToInstance(ContactItem, places.result.items) as unknown as ContactItem[];
    }
  }

}
