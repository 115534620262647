import {CustomDate} from '../classes/customDate';

export {};

declare global {
  interface Date {
    toCustomDate(): CustomDate;
  }
}

Date.prototype.toCustomDate = function(): CustomDate {
  return new CustomDate(this);
};

const transformDate = ({value}) => value ? new CustomDate(value) : null;
