import {inject, Injectable} from '@angular/core';
import {AppService} from '@services/app.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import HttpClientUtils from '@core/httpClientUtils';
import {serialize} from 'object-to-formdata';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class MainModelService {
  public baseUrl = environment.baseUrl;
  public url: string | undefined;
  public data: any | undefined;
  protected apiUrl = environment.apiUrl;
  protected http = inject(HttpClient);
  protected appService = inject(AppService);
  protected model: string | undefined;

  private activeRequests: { [key: number]: Subscription } = {};

  getRequest(
    options: { getData?: any; url?: string | null; loading?: boolean; message?: boolean | string; cancelable?: boolean } = {
      getData: {},
      url: null,
      loading: true,
      message: false,
      cancelable: true
    }
  ) {
    return this.serverRequest(
      options.url,
      'GET',
      {},
      options.getData,
      {},
      options.loading,
      options.message,
      options.cancelable);
  }

  jsonRequest(
    options: { getData?: any; url?: string | null; loading?: boolean; message?: boolean | string; cancelable?: boolean } = {
      getData: {},
      url: null,
      loading: true,
      message: false,
      cancelable: true
    }
  ) {
    return this.serverRequest(
      options.url,
      'JSON',
      {},
      options.getData,
      {},
      options.loading,
      options.message,
      options.cancelable);
  }

  postRequest(
    options: { postData?: any; getData?: any; url?: string | null; loading?: boolean; message?: boolean | string; cancelable?: boolean } = {
      postData: {},
      getData: {},
      url: null,
      loading: true,
      message: false,
      cancelable: true
    }
  ) {
    return this.serverRequest(
      options.url,
      'POST',
      options.postData,
      options.getData,
      {},
      options.loading,
      options.message,
      options.cancelable);
  }

  putRequest(
    options: { postData?: any; getData?: any; url?: string | null; loading?: boolean; message?: boolean | string; cancelable?: boolean } = {
      postData: {},
      getData: {},
      url: null,
      loading: true,
      message: false,
      cancelable: true
    }
  ) {
    return this.serverRequest(
      options.url,
      'PUT',
      options.postData,
      options.getData,
      {},
      options.loading,
      options.message,
      options.cancelable);
  }

  deleteRequest(
    options: { postData?: any; getData?: any; url?: string | null; loading?: boolean; message?: boolean | string; cancelable?: boolean } = {
      postData: {},
      getData: {},
      url: null,
      loading: true,
      message: false,
      cancelable: true
    }
  ) {
    return this.serverRequest(
      options.url,
      'DELETE',
      options.postData,
      options.getData,
      {},
      options.loading,
      options.message,
      options.cancelable);
  }

  // todo добавить обработку loading
  serverRequest(
    url: string,
    method: string = 'GET',
    postData: any,
    getData: any = [],
    headers: any = null,
    loading: boolean = true,
    message: boolean | string = false,
    cancelable: boolean = true
  ) {
    return new Promise((resolve, reject) => {
      url = this.getUrl(url);

      const params = this.getParams(getData);

      const options: any = {
        params,
        headers,
        withCredentials: false,
        observe: 'response'
      };

      let skipSerialize = false;
      if (Array.isArray(postData)) {
        skipSerialize = !!(arr => arr.every(i => typeof i === 'string' || typeof i === 'number'));
      }
      if (!skipSerialize) {
        postData = serialize(postData, {indices: true, nullsAsUndefineds: false, allowEmptyArrays: true}) as FormData;
      }
      let request;
      switch (method) {
        case 'GET':
          request = this.http.get<any>(url, options);
          break;
        case 'JSON':
          request = this.http.jsonp<any>(url + '?' + params.toString(), getData.callback);
          break;
        case 'DELETE':
          options.body = postData;
          request = this.http.delete<any>(url, options);
          break;
        case 'POST':
          request = this.http.post<any>(url, postData, options);
          break;
        case 'PUT':
          request = this.http.put<any>(url, postData, options);
          break;
      }

      const hash = this.getHash(url);
      if (cancelable) {
        if (this.activeRequests[hash]) {
          this.activeRequests[hash].unsubscribe();
        }
      }
      this.activeRequests[hash] = request.subscribe((data) => {
        resolve(data.body);
      }, (e) => {
        reject(e);
      }, () => {
        delete this.activeRequests[hash];
      });
    });
  }

  public getUrl(url: string | null = null): string {
    if (url && (url.includes('http://') || url.includes('https://'))) {
      return url;
    }
    return this.baseUrl + this.apiUrl + (this.model ? this.model : '') + (url ? '/' + url : '');
  }

  private getParams(array: any) {
    return HttpClientUtils.toHttpParams(array);
  }

  private getHash(str) {
    let h: number;
    for (let i = 0; i < str.length; i++) {
      // eslint-disable-next-line no-bitwise
      h = Math.imul(31, h) + str.charCodeAt(i) | 0;
    }
    return h;
  };

}
