import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MainModalService {
  buttonTextSource = new Subject<string>();
  buttonText$ = this.buttonTextSource.asObservable();

  buttonClickSource = new Subject<void>();
  buttonClick$ = this.buttonClickSource.asObservable();

  setButtonText(buttonText: string) {
    this.buttonTextSource.next(buttonText);
  }

  emitButtonClick() {
    this.buttonClickSource.next();
  }
}
