import {AppComponent} from './app.component';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from '@core/interceptors/token.interceptor';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpErrorInterceptor} from '@core/interceptors/httpError.interceptor';
import {FirebaseX} from '@awesome-cordova-plugins/firebase-x/ngx';
import {ModalsModule} from '@components/modals/modals.module';
import {DirectivesModule} from '@core/directives/directives.module';
import {BottomMenuComponentModule} from '@components/ui/bottom-menu/item.module';
import {Camera} from '@awesome-cordova-plugins/camera/ngx';
import {AngularYandexMapsModule, YaConfig} from 'angular8-yandex-maps';
import {QRCodeModule} from 'angular2-qrcode';
import {LoaderComponentModule} from '@components/ui/loader/item.module';
import {BottomMainModalBtnComponentModule} from '@components/ui/bottom-main-modal-btn/item.module';
import {MainModalService} from '@services/mainModal.service';
import {PageHeaderComponentModule} from "@components/ui/page-header/item.module";
import {SimpleMaskModule} from "@initru/ngx-ion-simple-mask";
import {IconComponentModule} from "@components/ui/icon/item.module";
import {SearchPagePageModule} from "@pages/search/page.module";


const mapConfig: YaConfig = {
  apikey: '683f1347-5f68-4db2-9bf4-3d6e584efba5',
  lang: 'ru_RU',
};


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IonicModule.forRoot({
      mode: 'md',
      scrollAssist: false
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ModalsModule,
    DirectivesModule,
    BottomMenuComponentModule,
    AngularYandexMapsModule.forRoot(mapConfig),
    QRCodeModule,
    LoaderComponentModule,
    BottomMainModalBtnComponentModule,
    PageHeaderComponentModule,
    SimpleMaskModule,
    IconComponentModule,
    SearchPagePageModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    FirebaseX,
    Camera,
    MainModalService
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
