import {ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {MainComponent} from '@core/main.component';
import {environment} from '@environments/environment';
import {Platform} from '@ionic/angular';
import {register} from 'swiper/element/bundle';
import {CordovaService} from '@services/cordova.service';
import {CatalogService} from '@models/catalog.service';
import {ModalService} from '@services/modal.service';
import {SurveyService} from '@models/survey.service';
import {DeepLinkService} from '@services/deep-link.service';
import {PushModalComponent} from '@components/modals/push-modal/item';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends MainComponent implements OnInit {
  platform = inject(Platform);
  cordovaService = inject(CordovaService);
  catalogService = inject(CatalogService);
  surveyService = inject(SurveyService);
  cdr = inject(ChangeDetectorRef);
  menuIsOpen = false;
  menuItems = [];
  private update: { needUpdate: boolean; version: any } = null;
  private modalService = inject(ModalService);
  private deepLinkService = inject(DeepLinkService);
  private appIsOpen = false;
  private appIsOpenTime = 0;
  private appIsOpenInterval = null;

  constructor() {
    super();
  }

  ngOnInit() {
    // если приложение было свёрнуто, то очищаем интервал, appIsOpen и appIsOpenTime
    this.platform.pause.subscribe(() => {
      this.appIsOpen = false;
      this.appIsOpenTime = 0;
      clearInterval(this.appIsOpenInterval);
    });

    // если приложение возвращено из свёрнутого режима - запускаем startCheckOpenApp
    this.platform.resume.subscribe(() => {
      this.startCheckOpenApp();
    });
    this.initializeApp();
  }

  async initializeApp() {
    this.appService.setTitle(environment.title);

    await this.platform.ready();
    await this.checkInternet();
    // todo временное и крайне сомнительное решение открывать пустую
    //  модалку и закрывать её после загрузки приложения. Сделано так из-за
    //  того, что ионик не хочет открывать модалку об отсутствиии интернета,
    //  если после старта приложения ещё не была хоть раз открыта хотя бы одна модалка
    //  похоже на то, что для инициализации логики модалок ионику нужен интернет
    this.modalService.openErrorModal({
      fakeModal: true
    }, (res) => {
    });
    if (!this.appService.isDevice()) {
      this.appService.deviceId = 'browsertoken';
    }
    if (this.appService.isCordova()) {
      this.startCheckOpenApp();
      await this.appService.subscribePush(() => {
      }, (data) => {
        if (data) {
          // показываем модалку если приложение в момент получения пуша открыто
          // или data.show === '1'
          // data.show = '1';
          // console.log('push data', data);
          if (data.show === '1' || this.appIsOpen) {
            this.modalService.makeOpenModal({
              component: PushModalComponent,
              showBackdrop: true,
              componentProps: {title: data.title, text: data.text, confirmText: data.buttonText},
              cssClass: `modal-select`,
              mode: 'md',
            }, (answer) => {});
          }

          if (data.path) {
            // this.navigationService.goTo(data.path);
            this.deepLinkService.openDeepLink(data);
          }
        }
      });
      await this.checkAppVersion((result) => {
        this.update = result;
        this.checkForLoading();
      });
    }
    await this.contactsModelService.getContacts();
    await this.catalogService.getCatalog();
    await this.userModelService.getProfile();
    // обновляем кличество опросов, если пользователь залогинен
    if (this.userModelService.isLogin()) {
      await this.surveyService.getSurveysCount();
    }
    await this.initKeyboard();

    this.appService.appReady = true;
    this.appService.appReadySubject.subscribe((status) => {
      if (status) {
        // @ts-ignore
        navigator.splashscreen?.hide();
        this.modalService.openMainPopup();
        this.userModelService.updateMenuItems();
      }
    });
  }

  startCheckOpenApp() {
    this.appIsOpenInterval = setInterval(()=> {
      this.appIsOpenTime ++;
      if (this.appIsOpenTime >= 5) {
        this.appIsOpen = true;
      }
    }, 1000);
  }

  async checkAppVersion(callback: (result: {needUpdate: boolean; version: any}) => void) {
    if (this.appService.isCordova()) {
      await this.catalogService.getCatalog();
      const data = await this.catalogService.settings.version;
      if (parseFloat(data.critical) !== 0) {
        const ver = this.appService.isIos() ? data.ios : data.android;
        const versionCode = String(this.appService.appVersionNumber).replace(/\./g, '');
        callback({
          needUpdate: parseFloat(ver) > parseFloat(versionCode),
          version: data
        });
      } else {
        callback({
          needUpdate: false,
          version: data
        });
      }
    } else {
      callback({
        needUpdate: false,
        version: null
      });
    }
  }

  checkForLoading() {
    if (this.update.needUpdate) {
      this.appService.appReady = true;
      if (this.update.version.critical.toString() === '2') {
        // this.appService.blockBack = true;
      }

      this.navigationService.goTo('update', {}, true, this.update.version);
    } else {
      this.navigationService.goTo('dashboard', {});
    }
  }

  async checkInternet() {
    // console.log('checkInternet', navigator.onLine);
    if (!navigator.onLine) {
      // @ts-ignore
      navigator.splashscreen?.hide();
      await this.modalService.openErrorModal({
        title: 'Отсутствует интернет соединение',
        text: 'Проверьте соединение и попробуйте подключиться заново',
        confirmText: 'Попробовать снова',
        cannotBeClosed: true,
        internetError: true
      }, (res) => {
        if (res) {
          if (!navigator.onLine) {
            // @ts-ignore
            navigator.splashscreen.show();
            this.modalService.closeModalByClass('modal-error');
            setTimeout(()=> {
              this.checkInternet();
            }, 1500);
          } else {
            this.modalService.closeModalByClass('modal-error');
          }
        }
      });
    } else {
      return;
    }
  }

  toggleMenuIsOpen(value: boolean) {
    this.menuIsOpen = value;
  }

  openIab() {
    if (this.cordovaService.isCordova()) {
      const browserRef = InAppBrowser.create(this.catalogService.settings.aboutUs, '_self', {
        location: 'yes', // Скрывает адресную строку
        toolbar: 'yes', // Включает верхний тулбар
        lefttoright: 'yes',
        closebuttoncaption: 'Назад', // Устанавливает текст кнопки "Назад"
        closebuttoncolor: '#484848', // Устанавливает цвет текста кнопки "Назад".
        toolbarcolor: '#FFFFFF', // Устанавливает цвет тулбара
        hidenavigationbuttons: 'yes', // Скрывает кнопки навигации,
        // fullscreen: 'no'
        zoom: 'no'
      });

      browserRef.on('loadstop').subscribe(event => {
        // Дополнительные действия при загрузке страницы
      });
    } else {
      this.appService.openLink(this.catalogService.settings.aboutUs);
    }
  }

  goTo(link: string) {
    if (link === 'about') {
      this.openIab();
    } else {
      this.navigationService.goTo(link);
      this.appService.closeMenu();
    }
  }

  private initKeyboard() {
    window.addEventListener('keyboardWillShow', () => {
      this.cordovaService.keyBoardShow = true;
      this.cdr.detectChanges();
    });

    window.addEventListener('keyboardDidHide', () => {
      this.cordovaService.keyBoardShow = false;
      this.cdr.detectChanges();
    });
  }
}
