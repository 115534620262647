import pkg from '../../package.json';

export const environment = {
  title: 'Z2S DEV',
  production: false,
  baseUrl: 'https://z2s.site.staging.lobsterlab.io/',
  // baseUrl: 'https://hrmdemo2.doxsw.com/',
  publicUrl: '',
  apiUrl: 'mobile',
  requestPrefix: 'api/',
  requestParam: 'r',
  version: pkg.version
};
