import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {ColorName, Icons} from '@entities/enums';

@Component({
  selector: 'app-icon',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent {
  @Input() size = 24;
  @Input() color: ColorName;
  @Input() isSvg = true;
  iconContent: SafeHtml;
  private iconName: Icons;

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private cd: ChangeDetectorRef
  ) {
  }

  get name(): Icons {
    return this.iconName;
  }

  @Input() set name(name) {
    this.iconName = name;
    if (this.isSvg) {
      this.getIcon();
    }
  };

  private getIcon() {
    const request = this.http
      .get(`assets/icons/${this.iconName}.svg`, {responseType: 'text'})
      .subscribe(data => {
        this.iconContent = this.sanitizer.bypassSecurityTrustHtml(data);
        this.cd.markForCheck();
        request.unsubscribe();
      }, () => {
        console.error(`<app-icon> name: ${this.name} not found`);
      });
  }
}
