import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ContactItem} from '@entities/contact.entity';

@Injectable({ providedIn: 'root' })
export class PlaceModalService {
  placeSubject = new Subject<ContactItem>();
  place = this.placeSubject.asObservable();

  open(place: ContactItem) {
    this.placeSubject.next(place);
  }

  close() {
    this.placeSubject.next(null);
  }
}
