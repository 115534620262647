import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {NgClass, NgIf, NgStyle} from '@angular/common';
import {LoaderComponent} from '@components/ui/loader/item';

@NgModule({
  imports: [
    IonicModule,
    NgStyle,
    NgClass,
    NgIf
  ],
  exports: [
    LoaderComponent
  ],
  declarations: [
    LoaderComponent
  ],
  providers: [],
})
export class LoaderComponentModule {
}
