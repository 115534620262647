import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalComponent} from '@core/modal.component';
import {ContactItem} from '@entities/contact.entity';
import {PlaceModalComponent} from '@components/modals/place/item';
import {ModalService} from '@services/modal.service';

@Component({
  selector: 'app-all-places-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class AllPlacesModalComponent extends ModalComponent implements OnInit, OnDestroy  {
  modalService = inject(ModalService);
  @Input() places: ContactItem[];
  isLoaded: boolean[] = [];

  ngOnInit() {
    // console.log('places', this.places);
  }

  ngOnDestroy(): void {}

  async openPlaceModal(place: ContactItem) {
    await this.modalService.openModal(
      PlaceModalComponent,
      {place,  classList: 'modal-place', customBreakpoints: [0, 0.5, 0.75, 1]},
      'full',
      'half',
      (res) => {});
  }

  onImagesLoad(index: number) {
    this.isLoaded[index] = true;
  }
}
