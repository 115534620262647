import {Component, HostBinding, inject} from '@angular/core';
import {MainComponent} from '@core/main.component';
import {BottomMenuItem} from '@core/classes/bottom-menu';
import {ActivatedRoute} from '@angular/router';
import {CordovaService} from '@services/cordova.service';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class BottomMenuComponent extends MainComponent {
  cordovaService = inject(CordovaService);
  constructor(private route: ActivatedRoute) {
    super();
    this.appService.bottomMenu.setItems([
      {
        id: 'menu',
        icon: 'coffee',
        title: 'Меню',
        link: 'menu'
      },
      {
        id: 'main',
        icon: 'user',
        title: 'Главная',
        link: 'dashboard'
      },
      {
        id: 'contacts',
        icon: 'location',
        title: 'Рестораны',
        link: 'contacts'
      },
      {
        id: 'chat',
        icon: 'messages',
        title: 'Чат',
        link: 'chat'
      }]);
  }


  @HostBinding('class')
  get classes() {
    return {
      'bottom-panel-hidden': this.appService.bottomMenu.hidden || this.cordovaService.keyBoardShow,
    };
  }

  getItems(): BottomMenuItem[] {
    return this.appService.bottomMenu.getItems();
  }

  isActive(item: BottomMenuItem) {
    if (this.navigationService.isCurrentUrl(item.link)) {
      // Если текущий URL соответствует пункту меню, то устанавливаем класс `active`
      return true;
    } else if (!this.navigationService.currentUrl && item.link === 'dashboard') {
      // Если текущий URL не определен (т.е. мы на стартовой странице), и пункт меню - "dashboard", то устанавливаем класс `active`
      return true;
    } else {
      return false;
    }
  }

  onClick(index: number, item: BottomMenuItem) {
    switch (item.id) {
      case 'menu':
        this.navigationService.goTo('menu');
        break;
      case 'main':
        this.navigationService.goTo('dashboard');
        break;
      case 'contacts':
        this.navigationService.goTo('contacts');
        break;
      case 'chat':
        this.appService.getWindow().Verbox('openSupport');
        break;
    }
    this.appService.bottomMenu.emitClick(index);
  }
}
