import {Component, inject} from '@angular/core';
import {MainComponent} from '@core/main.component';
import {MainModalService} from '@services/mainModal.service';

@Component({
  selector: 'app-bottom-main-modal-btn',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class BottomMainModalBtnComponent extends MainComponent {
  mainModalService = inject(MainModalService);
  buttonText = '';
  constructor() {
    super();
    this.mainModalService.buttonText$.subscribe((buttonText) => {
      // Обработка значения buttonText
      this.buttonText = buttonText;
    });
  }

  buttonEvent() {
    this.mainModalService.emitButtonClick();
  }
}
