import {AfterViewInit, Component, ElementRef, inject, ViewChild} from '@angular/core';
import {ModalComponent} from '@core/modal.component';
import {CatalogService} from '@models/catalog.service';
import {Referral} from '@entities/referral.entity';


@Component({
  selector: 'app-referral-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})

export class ReferralModalComponent extends ModalComponent implements AfterViewInit{
  catalogService = inject(CatalogService);
  referral: Referral;
  stockId: number;
  isLoaded = false;
  copyingSucceeded = false;

  ngAfterViewInit() {}

  ionViewWillEnter() {
    this.referral = this.catalogService.settings.popups.referral;
  }

  onImageLoad() {
    // Изображение успешно загрузилось, скрываем лоадер
    this.isLoaded = true;
  }

  copyReferralId() {
    const referralId = this.userModelService.profile.referralId;

    if (referralId) {
      navigator.clipboard.writeText(referralId)
        .then(() => {
          this.copyingSucceeded = true;
          setTimeout(()=> {
            this.copyingSucceeded = false;
          }, 3000);
        })
        .catch((err) => {});
    }
  }

  sharing() {
    const shareText =
      this.catalogService.settings.popups?.referral?.shareText.replace('{promo_code}', this.userModelService.profile?.referralId);
    if (navigator.share) {
      navigator.share({
        text: shareText,
      }).then(() => {
      })
        .catch(console.error);
    } else {
      console.log('Web Share API не поддерживается');
    }
  }
}
