import {Injectable} from '@angular/core';
import {MainModelService} from '@core/mainModel.service';
import { ApiResponse } from '@entities/apiResponse.entity';
import {FormItem} from '@entities/stock.entity';

@Injectable({
  providedIn: 'root'
})
export class FeedbackModelService extends MainModelService {

  sendForm(form: FormItem): Promise<any> {
    return this.postRequest({
      postData: {
        form
      },
      url: 'api/v1/feedback/app/promo/'
    }) as Promise<ApiResponse>;
  }
}
