import {Component, EventEmitter, Input, NgZone, Output, ViewChild} from '@angular/core';
import {InputChangeEventDetail, IonInput} from '@ionic/angular';
import {AbstractNgModelComponent} from '../ng-model.component';

// @TODO pass input model type via component call

@Component({template: ''})
// @ts-ignore
export abstract class IonInputBaseComponent extends AbstractNgModelComponent<string> implements IonInput {

  @ViewChild('ionInput', {static: false}) ionInputElement: IonInput;

  @Input() clearInput: boolean;
  @Input() disabled: boolean;
  @Input() placeholder?: string;
  @Input() required?: boolean = false;
  @Input() mask?: string;
  @Input() isCodeInputCell?: boolean = false;
  @Input() type?: 'text' | 'password' | 'email' | 'tel' | 'search' | 'url' = 'text';
  @Input() accept: string;
  @Input() autocapitalize: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters' = 'on';
  @Input() autocomplete: 'name' | 'email' | 'tel' | 'url' | 'on' | 'off' | 'honorific-prefix' | 'given-name' |
    'additional-name' | 'family-name' | 'honorific-suffix' | 'nickname' | 'username' | 'new-password' |
    'current-password' | 'one-time-code' | 'organization-title' | 'organization' | 'street-address' | 'address-line1' |
    'address-line2' | 'address-line3' | 'address-level4' | 'address-level3' | 'address-level2' | 'address-level1' |
    'country' | 'country-name' | 'postal-code' | 'cc-name' | 'cc-given-name' | 'cc-additional-name' | 'cc-family-name' |
    'cc-number' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year' | 'cc-csc' | 'cc-type' | 'transaction-currency' |
    'transaction-amount' | 'language' | 'bday' | 'bday-day' | 'bday-month' | 'bday-year' | 'sex' | 'tel-country-code' |
    'tel-national' | 'tel-area-code' | 'tel-local' | 'tel-extension' | 'impp' | 'photo' = 'on';
  @Input() autocorrect: 'on' | 'off' = 'on';
  @Input() autofocus: boolean;
  @Input() clearOnEdit: boolean;
  @Input() spellcheck: boolean;
  @Input() debounce: number;
  @Input() enterkeyhint: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';

  @Input() inputmode: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' = 'text';
  @Input() max: string | number;
  @Input() min: string | number;
  @Input() minlength: number;
  @Input() maxlength?: number;
  @Input() name: string;
  @Input() pattern: string;
  @Input() readonly: boolean;
  @Input() fireFocusEvents: boolean;

  @Input() color: 'light' | 'dark';
  @Input() mode: 'ios' | 'md' = 'md';
  @Input() size: any;
  @Input() multiple: any;
  @Input() step: string;

  @Output() keyupEvent: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() clickEvent: EventEmitter<MouseEvent> = new EventEmitter();

  @Output() ionBlur: EventEmitter<CustomEvent<FocusEvent>> = new EventEmitter();
  @Output() ionChange: EventEmitter<CustomEvent<InputChangeEventDetail>> = new EventEmitter();
  @Output() ionFocus: EventEmitter<CustomEvent<FocusEvent>> = new EventEmitter();
  @Output() ionInput: EventEmitter<CustomEvent<InputChangeEventDetail>> = new EventEmitter();

  protected el: HTMLElement;
  protected z: NgZone;

  onBlur(event: CustomEvent<FocusEvent> | any) {
    if (this.fireFocusEvents) {
      this.ionBlur.emit(event);
    }

    this.onTouchModel(event);
  }

  onFocus(event: CustomEvent<FocusEvent> | any) {
    if (this.fireFocusEvents) {
      this.ionFocus.emit(event);
    }
  }

  onInput(event: CustomEvent<InputEvent> | any) {
    this.ionInput.emit(event);
  }

  onChange(event: CustomEvent<InputChangeEventDetail> | any) {
    this.value = event.detail.value;
    // this.ionChange.emit(event);
  }

  onKeyup(event: KeyboardEvent) {
    this.keyupEvent.emit(event);
  }

  onClick(event: MouseEvent) {
    this.clickEvent.emit(event);
  }

  getInputElement(): Promise<HTMLInputElement> {
    return this.ionInputElement.getInputElement();
  }

  setBlur(): Promise<void> {
    return this.ionInputElement.getInputElement().then((item) => {
      item.blur();
    });
  }

  setFocus(): Promise<void> {
    return this.ionInputElement.getInputElement().then((item) => {
      item.focus();
    });
  }

}
