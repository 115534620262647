import {KeyValue} from '@angular/common';
import {Injectable} from '@angular/core';
import {
  format,
  formatISO,
  parseISO,
} from 'date-fns';
import {ru} from 'date-fns/locale';
import {File} from '../entities/file.entity';
import {FileGroupTypes} from '../entities/enums';
import {CustomDate} from '../core/classes/customDate';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  static weekdayNames = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
  static weekdayNamesShort = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

  static random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-zа-я\\d%_.,~+\\s\\(\\)]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  static validBlob(str) {
    const pattern = new RegExp('^blob', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static ObjectKeys = Object.keys;

  static randomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async wait(condFunc: () => boolean) {
    return new Promise((resolve: any) => {
      if (condFunc()) {
        resolve();
      } else {
        setTimeout(async () => {
          await this.wait(condFunc);
          resolve();
        }, 300);
      }
    });
  }

  // @deprecated
  static strToDate(str) {
    if (str && str !== '') {
      const t = str.split('.');
      if (t.length === 3
        && t[0] && t[1] && t[2]
        && parseInt(t[0], 10) !== 0 && parseInt(t[1], 10) !== 0 && parseInt(t[2], 10) !== 0
      ) {
        return new Date(parseInt(t[2], 10), parseInt(t[1], 10) - 1, parseInt(t[0], 10));
      }
    }
    return null;
  }

  // @deprecated
  static dateIsoToFormat(date, newFormat = 'dd.MM.yyyy') {
    if (typeof date === 'string') {
      date = parseISO(date);
    }

    return format(date, newFormat, {locale: ru});
  }

  // @deprecated
  static dateToIso(date) {
    return date ? formatISO(date) : null;
  }

  static firstToUpperCase(str: string) {
    return str ? (str.charAt(0).toUpperCase() + str.slice(1)) : null;
  }

  static fileExt(type: (FileGroupTypes)[] = ['video', 'image', 'doc']): string[] {

    const map = {
      video: [
        'mp4', 'avi', 'wmv', 'mov', '3gp', 'flv', 'mpeg', 'webm'
      ],
      image: [
        'jpeg', 'jpg', 'gif', 'png'
      ],
      doc: [
        'pdf', 'docx', 'doc', 'rtf', 'xls', 'xlsx'
      ]
    };

    let res: string[] = [];

    type.forEach((t) => {
      res = [...res, ...map[t]];
    });

    return res;
  }

  static formatNumber(x) {
    return x.toString().replace(/\s/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  static removeHttp(url) {
    return url.replace(/^https?:\/\//, '');
  }

  static getNativeFileExt(file) {
    return file.name.split('.').pop().toLowerCase();
  }

  static getFormattedPhone(phone: string, code: string = '+7'): string | null {
    if (phone) {
      return `${code} ` + phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');;
    }

    return null;
  }

  // @todo SAKURA-114 все методы связанные с работой даты надо вынести в отдельный класс, который отнаследовать от стандартной даты, и написать туда все необходимые мтоды по работе с ними

  static dateIsoToMonth(created: string) {

  }

  static dateIsoToYear(created: string) {

  }

  // Кастует определенный тип на переменную (полезно если обращение к переменной происходит в шаблоне)
  static asCustomDate(date: any): CustomDate {
    return date;
  }

  static getFormattedPrice(price: any) {
    try {
      return price ? new Intl.NumberFormat('ru-RU').format(price) : 0;
    } catch (e) {
      console.error(e);
      return price;
    }
  }

  static countFiles(files: File[], typeOfFile: string[]) {
    if (typeOfFile.includes('all')) {
      return files.length;
    }
    let returnCount = 0;
    files.forEach((value, index) => {
      if (typeOfFile.includes(value.fileType())) {
        returnCount++;
      }
    });
    return returnCount;
  }

  static getFiles(files: File[], typeOfFile: string[]) {
    const returnFiles = [];
    files.forEach((value, index) => {
      if (typeOfFile.includes(value.fileType())) {
        returnFiles.push(value);
      }
    });
    return returnFiles;
  }

  static nounDeclension(numb: number, words: any []) {
    numb = Math.abs(numb) % 100;
    if (numb > 10 && numb < 20) {
      return words[2];
    }
    const num = numb % 10;
    if (num > 1 && num < 5) {
      return words[1];
    }
    if (num === 1) {
      return words[0];
    }
    return words[2];
  }

  static parseUrlForPushData() {
    const urlParams: any = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams.entries());

    const data: any = HelperService.parseUrlForGetParams(params);

    return typeof data.pushData !== 'undefined' ? data.pushData : null;
  }

  static parseUrlForGetParams(params) {
    const newObj = {};

    for (const i in params) {
      const a = i.match(/([^\[\]]+)(\[[^\[\]]+[^\]])*?/g);
      let p = params[i];
      let j = a.length;

      while (j--) {
        const q = {};
        q[a[j]] = p;
        p = q;
      }
      // merge object
      let k = Object.keys(p)[0];
      let o = newObj;

      while (k in o) {
        p = p[k];
        o = o[k];
        k = Object.keys(p)[0];
      }

      o[k] = p[k];
    }

    return newObj;
  }

  static camelToDashCase(key) {
    const result = key.replace(/([A-Z])/g, ' $1');
    return result.split(' ').join('-').toLowerCase();
  }

}
