import {inject, Injectable} from '@angular/core';
import {FirebaseX} from '@awesome-cordova-plugins/firebase-x/ngx';


@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  public firebase = inject(FirebaseX);

  getToken() {
    return this.firebase.getToken();
  }
}
