import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {ChildComponent, SearchPageComponent} from './page';
import {IonicModule} from '@ionic/angular';
import {PageHeaderComponentModule} from '@components/ui/page-header/item.module';
import {SearchComponentModule} from '@components/ui/search/item.module';
import {LoaderComponentModule} from '@components/ui/loader/item.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: SearchPageComponent
      }
    ]),
    IonicModule,
    PageHeaderComponentModule,
    SearchComponentModule,
    LoaderComponentModule,
  ],
  exports: [
    ChildComponent
  ],
  declarations: [SearchPageComponent, ChildComponent]
})
export class SearchPagePageModule {

}
